@font-face {
  font-family: 'gilroy';
  src: url("fonts/gilroy/Gilroy-Light.ttf");
  src: url("fonts/gilroy/Gilroy-Light.ttf?#iefix") format('truetype');
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'gilroy';
  src: url("fonts/gilroy/Gilroy-Regular.ttf");
  src: url("fonts/gilroy/Gilroy-Regular.ttf?#iefix") format('truetype');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'gilroy';
  src: url("fonts/gilroy/Gilroy-Medium.ttf");
  src: url("fonts/gilroy/Gilroy-Medium.ttf?#iefix") format('truetype');
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'gilroy';
  src: url("fonts/gilroy/Gilroy-SemiBold.ttf");
  src: url("fonts/gilroy/Gilroy-SemiBold.ttf?#iefix") format('truetype');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'gilroy';
  src: url("fonts/gilroy/Gilroy-Bold.ttf");
  src: url("fonts/gilroy/Gilroy-Bold.ttf?#iefix") format('truetype');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'gilroy';
  src: url("fonts/gilroy/Gilroy-ExtraBold.ttf");
  src: url("fonts/gilroy/Gilroy-ExtraBold.ttf?#iefix") format('truetype');
  font-weight: 900;
  font-style: normal; }
