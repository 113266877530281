.owl-carousel {
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both; }

  .owl-animated-in {
    z-index: 0; }

  .owl-animated-out {
    z-index: 1; }

  .fadeOut {
    animation-name: fadeOut; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }
