/* select 2 */
.overlay-custom#invite-friend-modal > .row.align-middle {
  .ember-power-select-trigger {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $border-color;
    padding-right: 40px;
    background: transparent;
    input.ember-power-select-trigger-multiple-input {
      margin-bottom: 0;
      border: none;
      background: transparent;
      border-radius: 0;
      padding: 0 8px;
      font-size: 16px; }
    li.ember-power-select-multiple-option {
      margin: 7px 4px 0 0;
      border-radius: 25px;
      background: $pink-light;
      border-color: $pink;
      color: $pink;
      padding: 0 25px 0 8px;
      position: relative;
      font-weight: 600;
      .ember-power-select-multiple-remove-btn {
        position: absolute;
        right: 5px;
        font-size: 20px;
        line-height: 1.2; } }
    .ember-power-select-status-icon {
      display: none; } } }
.ember-power-select-dropdown.ember-basic-dropdown-content--below {
  // display: none
  z-index: 10001; }

body.ember-application {
  /* float label select 2 */
  .float-label-power-select-wrapper {
    margin-bottom: 2rem;
    position: relative;
    font-family: "gilroy", "montserrat", "Open Sans", Arial;
    > label {
      position: absolute;
      z-index: 1;
      top: 11px;
      left: 14px;
      font-size: 14px;
      color: $input-label;
      font-weight: 400; }
    > a.hint-button {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      @include transform(translateY(100%));
      margin-right: 8px;
      color: $pink;
      font-size: 14px;
      font-weight: 400;
      &:hover {
        text-decoration: underline; } }
    > .ember-power-select-trigger.ember-basic-dropdown-trigger.ember-view {
      font-family: "gilroy", "montserrat", "Open Sans", Arial;
      border-color: $input-field-bg;
      padding: 0 16px 0 12px;
      background-color: $input-field-bg;
      border-radius: 8px;
      min-height: 61px;
      outline: none;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      color: $black;
      &.ember-basic-dropdown-trigger--below {
        border-radius: 8px 8px 0 0; }
      .ember-power-select-placeholder {
        margin: 0; }
      .ember-power-select-selected-item {
        margin: 30px 0 4px 0;
        text-align: left;
        line-height: 1.2;
        i {
          display: none; } }
      .ember-power-select-clear-btn {
        top: 33px; } } }
  .ember-basic-dropdown-content.ember-power-select-dropdown.float-label-power-select-dropdown {
    font-family: "gilroy", "montserrat", "Open Sans", Arial;
    border-color: $gray-5;
    border-radius: 0 0 8px 8px;
    padding: 0 0 8px 0;
    @include box-shadow(1px 1px 6px rgba(0,0,0,0.1));
    .ember-power-select-search input {
      border-radius: 8px;
      border-color: $gray-5;
      margin-bottom: 0;
      min-height: 35px;
      height: auto;
      padding: 0 8px; }
    li.ember-power-select-option {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      border-bottom: 1px solid $gray-5;
      min-height: 45px;
      @include transition(all 0.2s ease);
      font-size: 14px;
      line-height: 1.2;
      color: $oil;
      &:last-child {
        border-bottom: none; }
      &[aria-current="true"] {
        background-color: $gray-5;
        color: $oil; }
      &.ember-power-select-option--search-message, &.ember-power-select-option--no-matches-message {
        min-height: auto; }
      .option-content {
        display: flex;
        align-items: flex-start;
        i {
          font-size: 16px;
          color: $oil;
          margin: 1px 6px 0 0; } } } } }
