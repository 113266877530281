.owl-theme {
  // Styling Next and Prev buttons
  .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    [class*='owl-'] {
      color: $nav-color;
      font-size: $nav-font-size;
      padding: $nav-padding;
      background: $nav-background;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;
      &:hover {
        background: $gray;
        color: $nav-color-hover;
        text-decoration: none; }
      &.owl-prev {
        float: left; }
      &.owl-next {
        float: right; }
      &:after {
        content: "";
        display: table;
        clear: both; } }

    .disabled {
      opacity: $nav-disabled-opacity;
      cursor: default; } }

  // Styling dots
  .owl-nav.disabled + .owl-dots {
    margin-top: 10px; }

  .owl-dots {
    margin-top: 8px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: $dot-width;
        height: $dot-height;
        margin: $dot-margin;
        background: $dot-background;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px; }

      &.active,
      &:hover {
        span {
          background: $gray; } } } } }
