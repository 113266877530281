/* global component values */
$column-gutter: 2rem;
// long form content
$section-video-max-width: 1280px;


/* public mixins for sweat app */
@mixin flex($align: center, $justify: center, $dir: row, $is_wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $is_wrap; }

@mixin background($bg_size: cover, $bg_position: center, $bg_repeat: no-repeat) {
  background-size: $bg_size;
  background-position: $bg_position;
  background-repeat: $bg_repeat; }

@mixin fa_icon_pro($content-code, $color: currentColor, $font-size: inherit) {
  position: absolute;
  content: $content-code;
  font-family: "Font Awesome 5 Pro";
  color: $color;
  font-size: $font-size; }

@mixin appearance($prop) {
  -webkit-appearance: $prop;
  -moz-appearance: $prop;
  appearance: $prop; }

@mixin hover_action() {
  @include transition(all 0.2s ease);
  @include transform(translate(0, -2px)); }

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color; }
  &:-moz-placeholder {
    color: $color; }
  &::-moz-placeholder {
    color: $color; }
  &:-ms-input-placeholder {
    color: $color; } }


/* global style */
body, html {
  overflow-x: hidden; }

.tabs-content {
  border: 0; }

a {
  @include transition(all 0.2s ease); }

a, button, .button, .orbit-container {
  outline: 0; }

/* flex row */
.row.flex-row {
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  > .columns {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    &.auto {
      flex: 1; }
    &.shrink {
      flex: 0; } }
  &.align-middle {
    align-items: center; } }

.block {
  display: block; }

.hidden {
  overflow: hidden !important;
  visibility: hidden !important;
  opacity: 0 !important;
  height: 0 !important;
  margin: 0 !important; }

.text-underline {
  text-decoration: underline; }

.inline-block {
  display: inline-block !important; }

.relative {
  position: relative; }

.static {
  position: static; }

.va-t {
  vertical-align: top !important; }

.va-m {
  vertical-align: middle !important; }

.va-b {
  vertical-align: bottom !important; }

.flex-va-m {
  display: flex;
  align-items: center;
  &.ha-c {
    justify-content: center; } }

.transit {
  @include transition(all 0.35s ease); }

.right {
  float: right; }

.left {
  float: left; }

.table-block {
  margin: 0;
  padding: 0;
  position: static;
  display: table;
  width: 100%;
  background-size: cover;
  table-layout: fixed;
  &.large .table-cell {
    padding: $column-gutter * 3; }
  &.medium .table-cell {
    padding: $column-gutter * 1.6; }
  &.small .table-cell {
    padding: $column-gutter / 2; }
  &.tiny .table-cell {
    padding: $column-gutter / 3; }
  & > .table-cell {
    display: table-cell;
    vertical-align: middle;
    padding: $column-gutter;
    height: 100%; } }

.notification-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: $white;
  border: 0.5px solid $pink;
  @include flex;
  .red-circle {
    min-height: 6px;
    max-height: 6px;
    min-width: 6px;
    max-width: 6px;
    border-radius: 50%;
    background: $pink; } }

.row {
  max-width: none;
  &.container {
    max-width: $global-width; } }

.container {
  max-width: $global-width;
  margin: 0 auto; }

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

.overflow-visible {
  overflow: visible; }

hr {
  max-width: none;
  &.large {
    margin: 3rem auto; }
  &.small {
    margin: 1rem auto; }
  &.wide {
    border-bottom-width: 3px;
    border-color: lighten($border-color, 15%); } }

//global foundation progress bar
.progress {
  border-radius: 3px;
  height: 20px;
  margin: 5px 0;
  .progress-meter {
    border-radius: 3px; }
  &.workout-progress {
    background: $gray-lighter;
    border-radius: 8px;
    height: 10px;
    margin: 5px 0;
    &.white {
      background: rgba(10,10,10,.2); }
    .progress-meter {
      border-radius: 8px; } } }
@each $name, $color in $color-map {
  .progress {
    &.#{$name} {
      .progress-meter {
        background: $color; } } } }
ul, ol {
  &.list-unstyled {
    list-style: none;
    margin: 0; }
  &.list-inline {
    list-style: none;
    margin: 0;
    li {
      float: none;
      display: inline-block;
      vertical-align: middle;
      margin-left: $column-gutter/2;
      &:first-child {
        margin-left: 0; } }
    &.category-title {
      @include flex(center, flex-start);
      > li {
        margin-left: $column-gutter/4;
        &:first-child {
          margin-left: 0; } } } } }

.switch.small {
  .switch-paddle {
    width: 2.6rem;
    border: 1px solid $border-color;
    &:after {
      width: 1.5rem;
      height: 1.5rem;
      top: 50%;
      transform: translate(0,-50%);
      border: 1px solid $border-color;
      @include box-shadow(0 3px 5px rgba(0,0,0,0.15)); } }

  input:checked ~ .switch-paddle {
    border-color: transparent;
    &.orange {
      background: $orange; }
    &[class*="blue"] {
      background: $blue; }
    &.green {
      background: $cardio; }
    &::after {
      left: 0.95rem;
      border-color: transparent; } } }

//////customized radio button starts//////////
.radio-container {
  position: relative;
  &:hover input ~ .radiomark {
    background-color: $pink-light; }
  input {
    opacity: 0;
    cursor: pointer;
    margin-right: 15px;
    &:checked {
      ~ .radiomark {
        border: 1px solid $pink;
        &:after {
          display: block; } }
      ~ span.label-text {
        color: $pink; } } }
  &.resistance {
    &:hover input ~ .radiomark {
      background: lighten($resistance, 40%); }
    .radiomark {
      border-color: $gray;
      &:after {
        background: $resistance; } }
    input:checked {
      ~ span.label-text {
        color: $resistance; }
      ~ .radiomark {
        border-color: $resistance; } } }

  &.cardio {
    &:hover input ~ .radiomark {
      background: lighten($cardio, 40%); }
    .radiomark {
      border-color: $gray;
      &:after {
        background: $cardio; } }
    input:checked {
      ~ span.label-text {
        color: $cardio; }
      ~ .radiomark {
        border-color: $cardio; } } }

  &.recovery {
    &:hover input ~ .radiomark {
      background: lighten($recovery, 30%); }
    .radiomark {
      border-color: $gray;
      &:after {
        background: $recovery; } }
    input:checked {
      ~ span.label-text {
        color: $recovery; }
      ~ .radiomark {
        border-color: $recovery; } } }

  &.challenge {
    &:hover input ~ .radiomark {
      background: lighten($challenge, 25%); }
    .radiomark {
      border-color: $gray;
      &:after {
        background: $challenge; } }
    input:checked {
      ~ span.label-text {
        color: $challenge; }
      ~ .radiomark {
        border-color: $challenge; } } }

  &.lifestyle {
    &:hover input ~ .radiomark {
      background: lighten($lifestyle, 20%); }
    .radiomark {
      border-color: $gray;
      &:after {
        background: $lifestyle; } }
    input:checked {
      ~ span.label-text {
        color: $lifestyle; }
      ~ .radiomark {
        border-color: $lifestyle; } } } }


.radiomark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid $gray;
  &:after {
    content: "";
    position: absolute;
    display: none;
    width: 10px;
    height: 10px;
    top: 2px;
    left: 2px;
    background: $pink;
    border-radius: 50%; } }
//////customized radio button ends//////////

//////customized checkbox starts//////////
.checkbox-container {
  display: inline-block;
  position: relative;
  margin: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover input ~ .checkmark {
    background-color: $pink-light; }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark {
      background-color: $pink;
      &:after {
        display: block; } } }
  + .field-wrap {
    margin-top: -10px;
    margin-bottom: 5px; } }

/* checkbox custom style */
.checkmark {
  position: absolute;
  top: -13px;
  left: 0;
  height: 16px;
  width: 16px;
  // background-color: $gray-light-bg
  border-radius: 2px;
  border: 1px solid $pink;
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: -1px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); } }
////////customized checkbox ends//////////////

////////issue display in my subscription/////////////
.media.issue-media {
  margin-top: 0 !important;
  padding: 15px;
  background: $pink-light;
  color: $gray;
  border-bottom: 1px solid $white;
  .media-left {
    position: relative;
    .subscription-expiring-inner {
      top: 6px;
      left: 12px;
      position: absolute;
      color: $white;
      font-size: 20px; }
    .trial-remaining-inner {
      top: 8px;
      left: 10px;
      position: absolute;
      color: $white;
      font-size: 18px; } } }
////////issue display in my subscription ends////////

////////////tabs style in dashboard starts///////////
ul.tabs#dashboard-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  li.tabs-title {
    flex: 1;
    text-align: center;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: $gray-lighter; }
    a {
      color: $gray-light;
      font-size: 1rem;
      font-weight: bold;
      padding: 25px 10px;
      @include flex; }
    &:not(.is-active) {
      a:hover {
        color: $gray; } }
    &.is-active {
      a {
        color: $black; }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: $black; } } } }
////////////tabs style in dashboard ends///////////

.summary-container {
  max-width: 500px;
  padding: 0 30px; }
.customise-textarea {
  position: relative;
  .textarea-label {
    position: absolute;
    top: -12px;
    left: 15px;
    padding: 3px 6px;
    background: white;
    font-size: 12px; }
  textarea {
    resize: none;
    padding: 20px;
    border-radius: $rebrand-global-radius;
    font-weight: 500;
    @include placeholder($gray-10);
    &:focus {
      border-color: $pink;
      ~ .textarea-label {
        color: $pink; } } } }

////////////setting page style starts///////////////
.setting-header {
  margin-top: 10px;
  padding: 40px 0 0 0;
  position: relative;
  #logout-btn {
    position: absolute;
    top: 40px;
    right: 0; }
  .img-circle {
    width: 150px;
    height: 150px; }
  .setting-text-container {
    max-width: 500px;
    margin: 0 auto; }
  .add-profile-img {
    height: 30px;
    width: 30px;
    background: $pink;
    color: $white;
    position: absolute;
    bottom: 10px;
    right: 0;
    border-radius: 1000px;
    border: 1px solid $white;
    @include box-shadow(0 3px 5px rgba(0,0,0,0.15));
    cursor: pointer;
    transition: all .2s linear;
    &:hover {
      transform: translateY(-2px); }
    i {
      font-size: 18px;
      line-height: 30px; } } }
.setting-details {
  .detail-header {
    line-height: 38px;
    .button.edit {
      padding: 10px;
      min-width: 100px;
      margin-bottom: 0; } }
  .detail-block {
    border: 1px solid $border-color;
    border-radius: 5px;
    margin-bottom: 30px;
    .detail-item {
      margin: 0;
      padding: 20px;
      &:not(:last-child) {
        border-bottom: 1px solid $border-color; }
      .media-body {
        font-weight: bold; } } } }
.setting-footer {
  padding: 40px 0;
  text-align: center;
  span {
    font-weight: 500;
    color: $gray; } }
////////////setting page style ends///////////////

////////in-app messaging & other programs New label tag/////////////
.pane-label-tag {
  position: absolute;
  padding: 6px;
  font-size: 10px;
  font-weight: 700;
  color: $white;
  line-height: 1;
  background-color: $pink;
  top: 15px;
  left: 15px;
  text-transform: uppercase;
  // +transform(translateY(-50%))
  border-radius: 3px;
  z-index: 1;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.block {
    position: static;
    @include transform(none);
    margin-left: 10px; }
  &.normal {
    font-weight: 400; }
  &.inverted {
    background-color: $white;
    color: $black; }
  &.subtle {
    background-color: $pink-light;
    color: $pink; }
  &.top-right {
    top: 20px !important;
    bottom: unset !important;
    right: 20px !important;
    left: unset !important; }
  &.top-left {
    top: 20px !important;
    bottom: unset !important;
    left: 20px !important;
    right: unset !important; }
  &.rounded {
    border-radius: 16px;
    padding: 4px 8px; } }


////////in-app messaging & other programs New label tag ends/////////////

////////credit card img in setting////////////////
.credit-card-img {
  @include flex(flex-start, space-between, column);
  padding: 18px 20px;
  background-color: $primary-color;
  background-image: linear-gradient(to right bottom, #fd696b, #fa616e, #f65871, #f15075, #ec4879);
  border-radius: 8px;
  max-width: 380px;
  height: 215px;
  margin: 0 auto;
  text-align: left;
  color: $white;
  position: relative;
  .credit-card-logo {
    position: absolute;
    bottom: 11px;
    right: 20px;
    font-size: 60px;
    &.card-logo-img {
      width: 80px;
      bottom: 18px; } }
  .card-number {
    word-spacing: 4px;
    letter-spacing: 2px;
    text-align: center;
    font-size: 16px;
    width: 100%; }
  p {
    color: white !important;
    margin-bottom: 0; } }
////////credit card img in setting////////////////


span.input-hint {
  position: absolute;
  bottom: 3px;
  color: $pink;
  font-size: 11px;
  line-height: 100%; }

dl {
  margin-bottom: 1.5rem;
  font-size: 14px;
  &.horizontal {
    dt {
      float: left;
      clear: left;
      width: auto;
      padding-right: 1rem;
      font-weight: normal;
      color: $gray; }
    dd {
      margin-bottom: 0.5rem; } } }

#main {
  background: white;
  min-height: 90vh;
 }  // padding-bottom: rem-calc(50)

/* global custome class */
.text-link {
  display: block;
  color: $primary-color;
  text-decoration: underline;
  @include transition(all 0.2s ease);
  width: fit-content;
  &:hover {
    @include transform(translate(0, -2px)); } }

.action-area-v {
  > .button {
    margin: 0 0 0.5rem 0;
    width: 100%;
    max-width: 100%;
    &:last-child {
      margin-bottom: 0; } } }

// invite friends email input
ul.ember-power-select-multiple-options {
  margin: 0; }

// progress circle layout
.progress-cirle-wrapper {
  position: relative;
  height: 24px;
  width: 24px;
  > .ember-progress-bar:first-child {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }
  > .ember-progress-bar:last-child {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    svg * {
      stroke: $gray-bg; } }
  svg {
    height: 24px !important;
    width: 24px !important; } }


@media #{$small-only} {
  hr {
    margin: 1.5rem auto;
    &.large {
      margin: 2rem auto; }
    &.small {
      margin: 1rem auto; } }

  ul, ol {
    &.list-inline {
      &.category-title {
        > li.trophy-list {
          margin-left: auto; } } } } }



