$color-base: #f8006f !default;
$color-white: #FFF !default;
$color-gray: #ddd !default;

//nav

$nav-color: $color-white !default;
$nav-color-hover: $color-white !default;
$nav-font-size: 24px !default;
$nav-rounded: 3px !default;
$nav-margin: 5px !default;
$nav-padding: 3px 6px !default;
$nav-background: $color-gray !default;
$nav-background-hover: $color-base !default;
$nav-disabled-opacity: 0.5 !default;

//dots

$dot-width: 10px !default;
$dot-height: 10px !default;
$dot-rounded: 30px !default;
$dot-margin: 5px 7px !default;
$dot-background: $color-gray !default;
$dot-background-active: $color-base !default;

@import "theme";
