$global-radius: 14px;
$button-radius: 25px;
$input-radius: 8px;
$default-button-width: 220px;
$expanded-button-max-width: 350px;
$header-height: 100px;
$header-height-small: 80px;


body.ember-application {

  /* header */
  header#header {
    border-bottom: none;
    width: 100%;
    background: $white;
    @include transition(all 0.2s ease);
    padding-bottom: $header-height;
    > .top-bar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 20;
      min-height: $header-height;
      &.on-scroll {
        @include box-shadow(0 5px 5px rgba(0,0,0,.1)); } } }


  /* footer */
  footer#footer {
    padding: 80px 0 140px 0;
    h2, h3 {
      font-family: "gilroy", "montserrat", "Open Sans", Arial;
      color: $black; }
    a {
      @include transition(all 0.2s ease); }
    .brand img {
      max-height: 24px;
      margin-bottom: $column-gutter; }
    h2 {
      margin: 0 0 $column-gutter*2 0;
      font-weight: 500;
      font-size: 17px;
      text-transform: none; }
    h3 {
      font-size: 17px;
      font-weight: 600;
      text-transform: none; }
    .social-links {
      display: flex;
      align-items: center;
      margin: 0 -0.5rem;
      > a {
        @include flex;
        color: $white;
        height: 30px;
        width: 30px;
        min-width: 30px;
        border-radius: 50%;
        background-color: $aubergine;
        font-size: 17px;
        &:hover {
          @include transform(translate(0, -2px)); } } }
    .list-unstyled {
      li {
        margin-bottom: 0;
        a {
          color: $gray-50;
          font-weight: 600;
          font-size: 15px;
          &:hover {
            text-decoration: underline; } } } }
    .copyright {
      padding: 0;
      margin-top: 100px;
      background-color: $white;
      text-align: left;
      color: #606267;
      font-size: 16px;
      font-weight: 600; } }


  /* main structure */

  /* typo update */
  font-family: "gilroy", "montserrat", "Open Sans", Arial;
  h1, h2, h3, h4, h5, p, span, a {
    font-family: "gilroy", "montserrat", "Open Sans", Arial; }
  h3, h4, h5 {
    font-weight: 600; }
  h1 {
    font-size: 22px; }
  h2 {
    font-size: 20px; }
  h3 {
    font-size: 17px; }
  h4 {
    font-size: 15px; }
  h5 {
    font-size: 13px; }
  p {
    font-size: 15px;
    font-weight: 500;
    color: $text-gray; }
  a:not(.button) {
    font-weight: 500; }

  .rebrand-text-title {
    font-size: 34px;
    font-weight: bold;
    letter-spacing: -0.5px;
    color: $gray-60; }

  p.rebrand-text-content {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: $gray-50; }

  .rebrand-text-subtitle {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.5px;
    color: $gray-60;
    margin-bottom: 8px; }

  h4.tile-category {
    color: $pink;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-weight: 600;
    margin: 0; }

  .rebrand-title-group {
    h4 {
      font-size: 11px !important;
      font-weight: 600 !important;
      color: $pink;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin: 0; }
    h1 {
      font-size: 34px;
      margin: 0;
      color: $black; }
    h2 {
      font-size: 20px;
      margin: 0;
      color: $black; }
    p {
      color: $text-gray;
      font-size: 15px;
      font-weight: 500;
      margin: 0; } }

  .check-text-group {
    border-radius: $rebrand-global-radius;
    background-color: $default-bg-gray;
    .check-text-row {
      padding: 0 20px;
      font-size: 14px;
      color: $oil;
      min-height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $border-color;
      &:last-child {
        border: none; }
      i {
        margin: 2px 20px 0 0; } } }

  /* border radius update */
  .button {
    border-radius: $button-radius;
    &:not(.control-button) {
      min-width: $default-button-width; }
    &.expanded {
      max-width: $expanded-button-max-width; }
    &.hollow.no-border {
      border-radius: 5px; }
    &[class*='price-block'] {
      min-width: unset; } }

  nav.action-bar {
    .button {
      min-width: 0; }
    &#post-workout-bar {
      background-color: $default-bg-gray;
      border-bottom: none;
      &.on-scroll {
        background-color: $white;
        border-bottom: 1px solid $gray-5; }
      .center-title, .text-link-button {
        font-size: 17px; } } }

  footer.footer-nav {
    box-shadow: none;
    .button {
      border-radius: 0; } }

  .card {
    border-radius: $global-radius;
    &.simple-card {
      background-color: $simple-card-bg;
      border: none;
      .card-divider {
        background-color: $simple-card-bg; }
      .simple-card-media {
        @include flex;
        p {
          margin-right: 5px;
          font-size: 18px; } } } }

  .workout-content-block {
    /* horizontal card */
    .workout-tile.horizontal-tile {
      border-radius: $global-radius;
      border: none;
      box-shadow: none;
      background-color: $horizontal-card-bg;
      .bg-area {
        border-top-right-radius: $global-radius;
        border-bottom-right-radius: $global-radius;
        overflow: hidden;
        background: $card-img-default-bg;
        .category-img {
          margin: 0;
          // height: calc(100% - 16px)
          height: 100%;
          background-color: $card-img-default-bg;
          border-radius: 0;
          background-size: cover;
          background-position: center;
          &.category-cover {
            background-size: cover; } } }
      &.transparent-tile {
        background-color: transparent;
        @include box-shadow(0 3px 8px -2px rgba(0,0,0,.15));
        .bg-area {
          background-color: transparent;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; } }
      .description-area {
        border: none;
        padding: 16px 0 16px 16px;
        .table-cell {
          height: 120px;
          padding: 8px;
          .tile-top {
            margin-bottom: $column-gutter;
            .category-top {
              margin: 0;
              line-height: 18px;
              font-weight: 600; }
            .subtitle-top {
              font-size: 10px;
              color: $pink;
              font-weight: 600; }
            .main-title {
              margin-bottom: 0;
              color: $black; }
            .subtitle-bottom {
              color: $card-subtitle-bottom-color;
              letter-spacing: -0.15px;
              font-size: 13px;
              font-weight: 500; }
            h4 {
              font-weight: 500 !important;
              letter-spacing: -0.15px; } }
          .tile-detail {
            font-size: 13px;
            font-weight: 500;
            color: $card-subtitle-bottom-color;
            p {
              font-size: 13px;
              font-weight: 500;
              color: $card-subtitle-bottom-color; } } } }
      .referrals-button {
        border-radius: 0; } }
    /* vertical card */
    .workout-tile.card > .card-divider {
      > .table-block {
        height: auto;
        .table-cell {
          padding: 8px 0;
          .tile-detail {
            font-size: 13px;
            font-weight: 500;
            color: $card-subtitle-bottom-color !important; } } } }
    /* img top card style update */
    .workout-tile.card:not(.simple-card):not(.overview-exercise):not(.timer-card):not(.equipment-tile) {
      border: none;
      box-shadow: none;
      background: $transparent;
      > .card-section {
        border: none;
        border-radius: $global-radius;
        overflow: hidden;
        & + .card-divider {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 0;
          background: $transparent; } } } }

  /* card in a list */
  ul.stacked-links > li.list-group-item > a > .media .media-body p {
    font-size: 13px !important;
    font-weight: 500;
    color: $card-subtitle-bottom-color !important; }

  nav.action-bar .notification-bar .in-app-msg-pane {
    border-radius: $global-radius;
    .pane-header {
      overflow: hidden;
      border-top-left-radius: $global-radius;
      border-bottom-left-radius: $global-radius; } }

  .workout-content-block .workout-tile.card {
    box-shadow: none;
    > .card-section {
      border-top-left-radius: $global-radius;
      border-top-right-radius: $global-radius;
      overflow: hidden; }
    > .card-divider {
      border-bottom-left-radius: $global-radius;
      border-bottom-right-radius: $global-radius;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 1rem;
      min-height: 100px;
      > .table-block .table-cell {
        padding: 0; }
      &:only-child {
        justify-content: center; } }
    &.equipment-tile > .card-divider {
      min-height: initial;
      padding: 0;
      border-radius: 0;
      > .table-block .table-cell > h3 {
        font-size: 15px;
        color: $gray-50 !important;
        font-weight: 500; } } }

  /* tab bar style update */
  ul.tabs#dashboard-tabs {
    border: 1px solid transparent;
    li.tabs-title {
      a {
        color: $gray-50;
        font-weight: 500; }
      &:after {
        height: 1px;
        background-color: $gray-light; }
      &.is-active {
        a {
          font-weight: 600; }
        &:after {
          background-color: $pink;
          height: 3px; } } } }

  /* list group */
  ul.list-group.stacked-links {
    border-radius: $global-radius;
    overflow: hidden;
    > li:first-child {
      border-top-left-radius: $global-radius;
      border-top-right-radius: $global-radius; }
    > li:last-child {
      border-bottom-left-radius: $global-radius;
      border-bottom-right-radius: $global-radius; } }

  // .workout-content-block .row-tiles .owl-carousel.workout-carousel a.workout-tile .optional-status
  //   background: $white
  //   color: $black
  //   font-size: 14px
  //   font-weight: 400
  //   padding: 6px
  //   border-radius: 3px
  //   line-height: 1

  .workout-content-block .row-tiles .owl-carousel.workout-carousel {
    .owl-stage {
      display: flex;
      align-items: stretch;
      .owl-item {
        display: flex;
        align-items: stretch;
        .item {
          display: flex;
          align-items: stretch; } } }
    a.workout-tile .card-status {
      position: absolute;
      top: 15px;
      left: 15px;
      @include flex;
      > * {
        padding: 5px;
        border-radius: 3px;
        font-weight: bold;
        z-index: 1;
        font-size: 12px;
        text-transform: uppercase; }
      .optional-status {
        color: $gray;
        background-color: $white;
        margin-right: 6px; }
      .completed-status {
        position: static;
        color: $card-img-default-bg;
        background-color: $gray; } } }

  /* Default tags layout */
  .workout-content-block .row-tiles .owl-carousel.workout-carousel a.workout-tile .pane-label-tag {
    font-size: 10px;
    // font-weight: 600
    padding: 6px 8px;
    text-transform: uppercase;
    &.completed-tag {
      background-color: $purple-50; } }

  /* Program carousel card tag */
  .workout-content-block .row-tiles .owl-carousel.workout-carousel .item > .pane-label-tag {
    top: 7%;
    left: 12%;
    right: unset; }

  /* dashboard other program card tag */
  .workout-content-block .workout-tile.card > .pane-label-tag {
    top: 7%;
    left: 3%;
    right: unset; }

  /* Workout actions control */
  .workout-actions .action-container {
    #prev-btn, #nxt-btn {
      @include transition(all 0.2s ease);
      i {
        font-size: 25px;
        color: $gray-60; }
      &:hover {
        @include transform(translate(0, -2px)); }
      &[disabled] {
        i {
          color: $gray-5; }
        &:hover {
          @include transform(none); } } }
    #pause-btn {
      margin: 0 60px;
      border-radius: 50%; } }

  /* Active workout view */
  section.workout-circuit .active-exercise {
    background-color: $gray-0;
    min-height: 100vh;
    .workout-actions {
      background-color: $gray-0; }
    .exercise-container {
      .activity-video-wrapper {
        position: relative; }
      .exit, .enter {
        position: relative;
        &.no-bottom-border {
          background-color: $white;
          > .exercise-media {
            border-radius: 0;
            @include box-shadow(none); } }
        .alternative-workout {
          position: absolute;
          top: 1%;
          right: 2%;
          z-index: 100; } }
      .exercise-detail-bottom {
        padding-top: 5vh;
        > h1 {
          font-size: 20px;
          font-weight: 600;
          color: $gray-60;
          &:first-of-type {
            font-size: 28px;
            font-weight: bold; } } }
      .exercise-reward {
        margin-top: 0;
        background-color: $white;
        padding-top: 50px;
        padding-bottom: 80px;
        border-radius: 0 0 $rebrand-global-radius $rebrand-global-radius;
        @include box-shadow(0px 7px 10px rgba(0,0,0,0.08));
        .exercise-reward-circle {
          border: 10px solid $gray-0; } } } }

  /* Cardio active workout view */
  section.workout-circuit.cardio-circuit {
    background-color: $deep-black;
    min-height: 100vh;
    .exercise .exercise-container {
      .exercise-break {
        h3 {
          color: #a1aabc;
          font-size: 0.8rem; } }
      .activity-progress-bar-wrapper .activity-progress-bar-circle #progress-bar {
        background-color: $deep-black;
        .timer-count {
          color: $white; } } }
    .workout-actions {
      background-color: $deep-black;
      .action-container {
        .action-title {
          color: $gray; }
        #pause-btn {
          margin: 0; } } }
    &.cardio-rest {
      background-color: $primary-color;
      .exercise .exercise-container {
        .exercise-break {
          h3 {
            color: $pink-lighter; } }
        .activity-progress-bar-wrapper .activity-progress-bar-circle #progress-bar {
          background-color: $primary-color; } }
      .workout-actions {
        background-color: $primary-color;
        .action-container {
          .action-title {
            color: $pink-lighter; } } } } }
  /* layout tweak */
  ul.trainer-list {
    display: flex;
    flex-wrap: wrap;
    > li.trainer-item {
      display: flex;
      flex-direction: column; } }

  .switch {
    .switch-paddle {
      border: none;
      background-color: #ECEEF5;
      &:after {
        border: none; } }
    > input:checked ~ .switch-paddle {
      background-color: #4cd964; } }

  nav.action-bar .notification-bar .in-app-msg-content .sweat-logo-circle {
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      margin-right: 3px;
      margin-bottom: 2px; } }

  ul.stacked-links {
    box-shadow: none; }

  ul.trainer-list li.trainer-item .trainer-img {
    border-radius: 50%;
    border: 1px solid #e6e6e6; }

  // Tables update
  .setting-details .detail-block {
    .media.detail-item {
      .media-body {
        font-size: 17px;
        font-weight: 500;
        color: $black; }
      .media-right {
        font-size: 16px;
        font-weight: 500;
        color: $gray; } } }

  // input field style update
  .field.float-label-field {
    display: flex;
    flex-direction: row;
    min-height: 56px;
    margin-bottom: $column-gutter;
    border-radius: $input-radius;
    background-color: $input-field-bg;
    padding: 0 12px;
    position: relative;
    border: 1px solid $transparent;
    &.field-error {
      border-color: $alert; }
    > input, select, textarea {
      margin: 0;
      order: 1;
      border-bottom: 0;
      background-color: $transparent;
      width: 100%;
      margin-top: 20px;
      flex: 1;
      @include placeholder($transparent);
      &[disabled=disabled] + label {
        opacity: 0.4; } }
    > label {
      order: 0;
      position: absolute;
      @include transition(all 0.2s ease);
      @include transform(translateY(-50%));
      font-size: 17px;
      font-weight: 400;
      color: $input-label;
      top: 50%;
      left: 12px;
      transform-origin: top left;
      i {
        font-size: 15px; } }
    > .input-unit {
      order: 2;
      position: static;
      margin-top: 0;
      margin-left: 0.5rem;
      min-height: 35px;
      @include flex; }
    > i {
      right: 12px;
      bottom: 20px; }
    > small.error {
      position: absolute;
      @include transform(translateY(100%));
      left: 0;
      bottom: 0;
      margin: 0; }
    > small.hint {
      position: absolute;
      @include transform(translateY(-100%));
      right: 0;
      top: 0;
      margin: 0; }
    > span.input-hint {
      position: absolute;
      @include transform(translateY(100%));
      left: 0;
      bottom: -5px;
      margin: 0; }
    // editing layout
    &.on-editing {
      > label {
        top: 17px;
        @include transform(translateY(-50%) scale(0.8)); }
      > input, select, textarea {
        @include placeholder($placeholder); } }
    &.force-editing {
      > label {
        top: 17px;
        @include transform(translateY(-50%) scale(0.8)); } } }

  // radio layout update
  .radio-container {
    display: flex;
    align-items: center;
    &.with-radio-detail {
      @include flex(flex-start, flex-start); }
    &.disabled {
      opacity: 0.3;
      pointer-events: none; }
    &:hover {
      .radiomark {
        background-color: $input-field-bg; } }
    input {
      display: none;
      &:checked {
        & ~ .radiomark {
          border-color: $black;
          border-width: 8px;
          &:after {
            content: none; } }
        & ~ span.label-text {
          color: $black; } } }
    p {
      margin-bottom: 0; }
    .radiomark {
      position: static;
      display: block;
      @include transform(none);
      @include transition(all 0.1s ease);
      height: 24px;
      width: 24px;
      min-width: 24px;
      margin-right: 10px;
      border: 2px solid $gray-light; }
    span.label-text {
      color: $black;
      font-size: 17px;
      display: block; } }

  .radio-buttons-wrapper {
    background-color: $default-bg-gray;
    border-radius: 14px;
    &.reverted-bg {
      background-color: $white; }
    > .radio-container {
      padding: 16px;
      border-bottom: 1px solid $gray-5;
      cursor: pointer;
      .radio-detail {
        margin-left: 10px; }
      &.readonly {
        cursor: auto; }
      &:last-child {
        border-bottom: none; } } }

  // chckbox layout update
  .checkbox-container {
    &.disabled {
      opacity: 0.3;
      pointer-events: none; }
    &:hover {
      .checkmark {
        background-color: $input-field-bg; } }
    input {
      display: none;
      &:checked {
        & ~ .checkmark {
          border-color: $black;
          background-color: $black;
          &:after {
            top: 9px;
            left: 9px; } }
        & ~ span.label-text {
          color: $black; } } }
    .checkmark {
      position: static;
      display: block;
      @include transform(none);
      @include transition(all 0.1s ease);
      height: 24px;
      width: 24px;
      min-width: 24px;
      margin-right: 10px;
      border: 2px solid $gray-light;
      border-radius: 6px; }
    span.label-text {
      color: $black;
      font-size: 17px;
      display: block; } }

  .icon-button {
    height: 32px;
    width: 32px;
    min-width: 32px;
    background: rgba(255,255,255,0.8);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    color: $black;
    &:after {
      margin-top: 3px;
      font-size: 22px;
      line-height: 1;
      color: $gray-50;
      font-weight: 300;
      font-family: "Font Awesome 5 Pro"; }
    &:hover {
      @include hover_action; }
    &.top-left {
      position: absolute;
      top: 20px;
      left: 20px; }
    &.top-right {
      position: absolute;
      top: 20px;
      right: 20px; }
    &.icon-close:after {
      content: '\f00d';
      margin-top: 2px;
      margin-left: 0;
      font-size: 21px; }
    &.icon-back:after {
      content: '\f104';
      font-size: 26px;
      margin-right: 3px; }
    &.icon-setting:after {
      content: '\f013';
      font-size: 20px; }
    &.icon-next:after {
      content: '\f105';
      font-size: 26px;
      margin-left: 3px; }
    &.icon-check:after {
      content: '\f00c';
      font-size: 18px;
      font-weight: 400; }
    &.icon-info:after {
      content: '\f05a'; } }

  .text-link-button {
    color: $gray-50;
    font-size: 15px;
    min-height: 32px;
    @include flex;
    width: fit-content;
    padding: 0 8px;
    &:hover {
      @include hover_action; } }

  // Workout Equipment tags style
  .equipment-tags {
    @include flex(center, flex-start);
    > span {
      padding: 7px 15px;
      border: 1px solid $gray-5;
      border-radius: 18px;
      font-size: 13px;
      color: $gray-50;
      font-weight: 500;
      margin-right: 8px; } }

  // Summary Block style
  .summary-block {
    background-color: $simple-card-bg;
    border-radius: $rebrand-global-radius;
    &.reverted {
      background-color: $white; }
    .summary-block-item {
      @include flex(center, space-between);
      padding: 17px 16px;
      border-bottom: 1px solid $gray-5;
      &:last-child {
        border-bottom: none; }
      span {
        color: $gray;
        font-weight: 500; }
      &.summary-button {
        &:first-child {
          border-radius: $rebrand-global-radius $rebrand-global-radius 0 0; }
        &:last-child {
          border-radius: 0 0 $rebrand-global-radius $rebrand-global-radius; }
        &.solo-summary-button {
          border-radius: $rebrand-global-radius; }
        &:hover {
          background-color: $gray-5; } } } } }
/* small screen style */
@media #{$small-only} {
  body.ember-application {
    /* header */
    header#header {
      padding-bottom: $header-height-small;
      > .top-bar {
        min-height: $header-height-small; } }

    /* footer */
    footer#footer {
      padding: 60px 0 70px 0;
      .social-links {
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        h2 {
          order: 0;
          margin-bottom: 10px; }
        a {
          order: 1;
          &:first-child {
            margin-left: 0; } } }
      .copyright {
        margin-top: 20px;
        font-size: 14px;
        text-align: left;
        padding: 60px 16px 0 16px; }
      .footer-mobile {
        .logo-img {
          height: 24px;
          width: auto;
          margin-bottom: 20px; }
        h2 {
          text-align: left;
          margin: 0 0 40px 0 !important; }
        .social-links {
          justify-content: flex-start;
          padding: 0;
          margin: 0 0 20px 0; }
        .footer-menus {
          margin-bottom: 20px;
          a {
            color: $text-gray;
            font-size: 17px;
            font-weight: 600;
            border-bottom: none;
            min-height: 48px;
            i {
              display: none; } } } } }

    .button:not(.control-button) {
      min-width: 120px; }

    .setting-details .detail-block {
      .media.detail-item {
        .media-right {
          padding-left: 5px;
          &.mobile-media-right-wrap {
            width: 40%;
            text-align: right;
            span {
              white-space: normal; } } } } }

    /* Active workout view */
    section.workout-circuit .active-exercise .exercise-container {
      // padding-bottom: 150px
      .exercise-reward {
        padding: 50px 0; } }

    /* workout tile */
    .workout-content-block .row-tiles .owl-carousel.workout-carousel {
      &.collapse-carousel {
        .owl-stage {
          margin-left: -20px;
          .owl-item {
            .item {
              margin: 0;
              padding-left: 20px; } } } }
      a.workout-tile {
        &.horizontal-tile {
          .bg-area {
            order: 1;
            min-height: 240px;
            flex: 1 0 100%;
            max-width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; }
          .description-area {
            order: 2;
            padding: 10px;
            flex: 1 0 100%;
            max-width: 100%; } }
        &.simple-card {
          width: calc(100vw - 40px); } } } } }
