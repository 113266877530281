// Sweat Ds
$resistance: #dd4764;
$cardio: #3ac5ac;
$challenge: #ff8e6a;
$recovery: #4d98fc;
$lifestyle: #ad7eff;

$pink: #f8006f;
$pink-light: #fdebee;
$blue-light: #e8f4f8;
$green-light: #edfaf8;
$orange-light: #fdf3ef;
$green: $cardio;
$orange: $challenge;
$blue: $recovery;
$dark-blue: darken($blue, 10%);
$transparent: transparent;

$black: #232B2B;
$white: #fff;
$gray: #8a8a8a;
$gray-light: #b4b4b4;
$dark-gray: darken($gray, 10%);
$text-gray: #475266;

$gray-bg: #eaeaea;
$gray-light-bg: rgb(250,250,250);

$white-text: #EFEFEF;
$black-text: #262626;

// $border-color: #bbbbbb
$border-color: #c4cbd8;
$secondary-color: $gray-light-bg;
$facebook: #505cb8;

// community events
$magenta: #FD4953;
$gray-lighter: #F1EFEF;

// design guide
$alert: #ff3b30;
$success: #4cd964;

/* ---- SWEAT rebrand updated colors ---- */
// newly added colors
$deep-black: #000000;
$aubergine: #210826;
$gray-0: #eceef4;
$gray-5: #d7dce7;
$gray-10: #c4cbd8;
$gray-20: #A1AABC;
$gray-40: #626D83;
$gray-50: #475266;
$gray-60: #242933;
$purple-0: #DDCCFF;
$purple-20: #9065E5;
$purple-50: #3D00B3;
$simple-card-bg: #f5f7fa;
$horizontal-card-bg: #f5f7fa;
$default-bg-gray: #f5f7fa;
$input-field-bg: $gray-0;
$input-label: #808a9f;
$transparent: rgba(255,255,255,0);
$sweat-logo-circle-border: #e6e6e6;
$card-subtitle-bottom-color: #808a9f;
$category-img-default-bg: $gray-0;
$card-img-default-bg: #D6DCE8;
$oil: $gray-50;
$placeholder: #CACACB;
// replaced colors
$resistance: $gray-60;
$cardio: #ff0055;
$challenge: $gray-60;
$recovery: $gray-60;
$lifestyle: $gray-60;
$pink: #ff0055;
$pink-lighter: #ffb4ce;
$black: $gray-60;
$gray: #808a9f;
$gray-light: #c4cbd8;
$gray-light-bg: rgb(245, 247, 250);
$black-text: #210826;
/* ---- SWEAT rebrand updated colors ---- */

/////////////////////////////////

// Color List
$color-list: 'gray-lighter' 'orange-light' 'green-light' 'blue-light' 'transparent' 'resistance' 'cardio' 'challenge' 'recovery' 'lifestyle' 'pink' 'pink-light' 'green' 'orange' 'blue' 'dark-blue' 'black' 'white' 'gray' 'gray-light' 'dark-gray' 'gray-bg' 'gray-light-bg' 'white-text' 'black-text' 'border-color' 'secondary-color' 'facebook' 'success' 'alert' 'oil';
$color-map: ('gray-lighter': $gray-lighter, 'orange-light': $orange-light, 'green-light': $green-light, 'blue-light': $blue-light,'transparent': $transparent, 'resistance': $resistance, 'cardio': $cardio, 'challenge': $challenge, 'lifestyle': $lifestyle, 'recovery': $recovery, 'pink': $pink, 'pink-light': $pink-light, 'green': $green, 'gray-light': $gray-light, 'orange': $orange, 'blue': $blue, 'dark-blue': $dark-blue, 'black': $black, 'white': $white, 'gray': $gray, 'dark-gray': $dark-gray, 'gray-bg': $gray-bg, 'gray-light-bg': $gray-light-bg, 'gray-dark-bg': $default-bg-gray, 'gray-0': $gray-0, 'gray-5': $gray-5, 'gray-10': $gray-10, 'gray-20': $gray-20, 'gray-40': $gray-40, 'gray-50': $gray-50, 'gray-60': $gray-60, 'white-text': $white-text, 'black-text': $black-text, 'border-color': $border-color, 'secondary-color': $secondary-color, 'facebook': $facebook, 'success': $success, 'alert': $alert, 'oil': $oil);
