/* guide doc variables */
$main-content-max-width: 1200px;
$element-gutter: 1rem;
$base-fontsize: 14px;
$mobile-width: 375px;
$side-mini-map-width: 280px;
$hint-area-bg: #E1EBFD;

/* guide doc mixins */
@mixin hover-action() {
  @include transform(translate(0, -1px));
  transition: all 0.2s ease 0s; }

@mixin list-style() {
  ol {
    counter-reset: item;
    margin-left: 0;
    > li {
      display: block;
      list-style: none;
      margin-bottom: $element-gutter + 0.25rem;
      &:last-child {
        margin-bottom: 0; }
      > .list-title {
        display: flex;
        align-items: center;
        margin-bottom: $element-gutter;
        &:before {
          font-family: "montserrat", "Open Sans", Arial, sans-serif;
          content: counters(item, ".") " ";
          counter-increment: item;
          font-weight: 700;
          line-height: 1.2;
          padding-right: 8px; }
        h1.doc, h2.doc, h3.doc, h4.doc, h5.doc, h6.doc {
          margin-bottom: 0; } } } }
  > ol {
    margin-bottom: 0;
    > li {
      margin-bottom: $element-gutter*2;
      > .list-title {
        border-bottom: 1px solid $border-color;
        padding-bottom: $element-gutter; } } } }

/* guide doc style */
body {
  &.style-guide-page {
    overflow-x: visible; }
  .guide-document-wrapper {
    // font-family: 'gilroy'
    background-color: $secondary-color;
    padding: $element-gutter*2 $element-gutter;
    display: flex;
    justify-content: center;
    position: relative;
    /* main document style */
    .guide-document-main {
      padding: $element-gutter*2 20px;
      min-height: calc( 100vh - #{$element-gutter*4} );
      max-width: $main-content-max-width;
      width: 100%;
      margin: 0;
      background-color: $white;
      border-radius: $global-radius;
      .document-heading {
        margin-bottom: $element-gutter*2;
        .title-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: $element-gutter*2; }
        h1.hero-title {
          margin-bottom: 0; }
        p.doc {
          margin-bottom: 0;
          font-size: $base-fontsize; }
        mark {
          font-size: 14px;
          margin: $element-gutter 0;
          display: inline-block;
          &:last-child {
            margin-bottom: 0; } } }
      .document-body {
        /* document content layout */
        @include list-style;
        /* document content private style */
        p.doc {
          font-size: $base-fontsize;
          margin-bottom: $element-gutter; }

        .tab-button-group {
          display: flex;
          align-items: center;
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 50px;
            height: 30px;
            color: $gray;
            border-bottom: 2px solid $transparent;
            @include transition(all 0.2s ease);
            &:hover {
              color: $pink; }
            i {
              margin-top: 2px; }
            &:first-child {
              font-size: 14px;
              margin-top: {}
              border-right: none;
              border-top-left-radius: $global-radius;
              i {
                margin-top: 4px; } }
            &:last-child {
              border-left: none;
              border-left-width: 0.5px;
              border-top-right-radius: $global-radius; }
            &.is-active {
              // background-color: $pink
              color: $pink;
              border-color: $pink; } } }

        .demo-block {
          // margin-bottom: $element-gutter
          padding: $column-gutter;
          border-radius: $global-radius;
          border-top-left-radius: 0;
          background-color: $white-text;
          display: flex;
          align-items: center;
          justify-content: center;
          .frame-wrapper, .block-wrapper {
            background-color: $white;
            width: 100%;
            @include transition(all 0.2s ease); }
          iframe {
            border: none;
            box-shadow: none;
            margin-bottom: -7px;
            overflow: hidden;
            &.header-frame {
              height: 60px;
              width: 100%; } } }

        .standard-list {
          display: flex;
          align-items: stretch;
          flex-wrap: wrap;
          font-size: 14px;
          .list-group {
            flex: 1;
            min-width: 350px;
            margin: 0 $element-gutter $element-gutter $element-gutter;
            // &:nth-child(odd)
            //   margin-right: $element-gutter/2
            // &:nth-child(even)
            //   margin-left: $element-gutter/2
            .list-title {
              font-weight: 600;
              ul {
                li {
                  font-size: 14px; } } } } } } }

    /* side map style */
    .side-mini-map {
      width: $side-mini-map-width;
      padding: $element-gutter*2 20px;
      padding-bottom: calc( #{$element-gutter}*2 - 8px );
      background-color: $white;
      border-radius: $global-radius;
      margin-left: $element-gutter;
      position: sticky;
      position: -webkit-sticky;
      top: $element-gutter*2;
      .map-heading {
        margin-bottom: $element-gutter*2;
        .title {
          font-weight: 700;
          font-size: 16px; } }
      .map-body {
        @include list-style;
        .list-title {
          font-size: 12px;
          cursor: pointer;
          @include transition(all 0.2s ease);
          &:hover {
            color: $pink;
            font-weight: 700; } }
        ol {
          margin: 0;
          li {
            margin: 0; } }
        > ol {
          > li {
            .list-title {
              margin-bottom: 8px;
              &:before {
                font-weight: 400; } }
            &.no-divider > .list-title {
              margin-bottom: 0;
              border-bottom: none;
              padding-bottom: 0; }
            > .list-title {
              padding-bottom: 8px;
              font-weight: 700;
              &:before {
                font-weight: 700; } }
            > ol {
              margin-left: 14px; } } } } } } }


/* iframe inner page style */
body {
  .web-app-demo {
    min-height: 100vh !important;
    section {
      margin-bottom: $element-gutter*2;
      padding-bottom: $element-gutter;
      border-bottom: 1px solid $border-color;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none; } }
    .demo-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: $element-gutter; }
    &.no-bg {
      background-color: $white-text !important; }
    /* iframe with intros */
    &.intro-demo {
      padding: $element-gutter;
      p {
        font-size: 12px;
        margin: 0;
        color: $gray; }
      .hint-area {
        margin: $element-gutter 0;
        padding: 10px 10px 10px 26px;
        border-radius: $global-radius;
        background-color: $hint-area-bg;
        max-width: $mobile-width;
        position: relative;
        .hint-title {
          font-weight: 700;
          margin-bottom: $element-gutter/2;
          &:before {
            position: absolute;
            content: '\f06a';
            font-family: "Font Awesome 5 Pro";
            color: $blue;
            font-size: 12px;
            transform: translate(calc(-100% - 4px), 1px); }
          &:not(:first-child) {
            margin-top: $element-gutter; } } } }
    /* iframe partial private style */
    &.carousel-bar-demo {
      padding: $element-gutter;
      .owl-carousel {
        margin-top: 0 !important; }
      .owl-stage-outer {
        padding-top: 0 !important; }
      .owl-item > .item > a {
        margin-bottom: 0 !important; } }
    &.table-demo {
      padding: $element-gutter;
      max-width: 540px;
      margin: 0 auto; }
    &.tag-demo {
      .pane-label-tag {
        width: fit-content;
        margin: 0; }
      .tag-group-wrapper {
        border-radius: $global-radius;
        padding: $element-gutter;
        width: fit-content;
        .tag-group-pane {
          width: fit-content;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin: 0 - $element-gutter;
          .item {
            margin: $element-gutter;
            p {
              margin-top: 8px; } } } } }
    &.tooltip-demo {
      .tooltip-group-wrapper {
        padding: 30px;
        .tooltip-demo-link {
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline;
          margin-bottom: $element-gutter; } } }
    &.banner-demo {
      .owl-carousel.workout-carousel.topbar-notice-carousel {
        .owl-stage-outer .owl-stage {
          .owl-item {
            .topbar-notice-item-wrapper {
              padding: 0 2rem 0 0; } } } } }
    &.input-demo {
      section {
        padding-bottom: 0;
        .field.float-label-field + p {
          margin-bottom: calc( #{$element-gutter} + 10px ); } } }
    &.colour-palette-demo {
      .color-pane-group {
        display: flex;
        align-items: center;
        .inner-group-v {
          flex: 1;
          display: flex;
          flex-direction: column;
          .color-pane {
            margin-bottom: $element-gutter;
            &:last-child {
              margin-bottom: 0; }
            &:not(:first-child) {
              min-height: 80px; } } }
        .color-pane {
          min-height: 120px;
          max-width: 200px;
          border-radius: 25px;
          padding: 20px;
          flex: 1;
          margin: 0 $element-gutter/2;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            color: $white;
            font-size: 16px;
            font-weight: 600; }
          .color-code {
            color: $white;
            font-size: 12px;
            text-align: right; } } } }
    &.header-demo > header#header {
      > .top-bar.stacked-for-medium {
        padding: 0 15px;
        display: flex;
        align-items: stretch;
        .top-bar-title {
          @include flex(center, flex-start); }
        .pull-right.show-for-large {
          flex: 1;
          @include flex(stretch, flex-end);
          ul.demo-header-tabs {
            margin-right: 30px;
            display: flex;
            align-items: stretch;
            border: none !important;
            > li {
              margin-right: 10px;
              > a {
                position: relative;
                display: flex !important;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 0 10px !important;
                font-size: 16px;
                font-weight: bold !important;
                color: #6e788f !important;
                &:after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  border-bottom: 3px solid $transparent; } }
              &.is-active {
                > a {
                  color: $pink !important;
                  &:after {
                    border-color: $pink; } } } } }
          ul.list-inline {
            @include flex; } }
        .pull-right.hide-for-large {
          @include flex; } } }
    &.buttons-demo {
      .icon-button-group {
        display: flex;
        align-items: center;
        margin-bottom: $element-gutter;
        padding: $element-gutter;
        border-radius: $global-radius;
        background-color: $white-text;
        width: fit-content;
        .button-item {
          margin-right: $element-gutter;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            margin: 8px 0 0 0; } } } }
    &.card-demo, &.tag-demo {
      .workout-content-block .row-tiles .owl-carousel {
        margin-left: 0 !important;
        .owl-stage-outer {
          padding-left: 10px; } } } } }


/* for small screen */
body {
  @include breakpoint(small only) {
    .guide-document-wrapper {
      .guide-document-main {
        .document-heading {}
        .document-body {
          .standard-list {
            flex-wrap: wrap;
            .list-group {
              flex: 1 1 100%;
              flex-wrap: wrap; } }
          .demo-block {
            padding: $element-gutter; } } } }
    .web-app-demo {
      &.card-demo, &.tag-demo {
        .workout-content-block .row-tiles .owl-carousel .owl-stage-outer {
          padding-left: 0; } }
      &.buttons-demo {
        .icon-button-group {
          flex-wrap: wrap;
          padding-bottom: 0;
          .button-item {
            margin: 0 0 1rem 0;
            flex: 1 0 50%; } } } } } }

