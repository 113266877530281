// Bordered & Pulled
// -------------------------

.#{$fa-css-prefix}-border {
  padding: .2em .25em .15em;
  border: solid 0.08em $fa-border-color;
  border-radius: .1em; }

.#{$fa-css-prefix}-pull-left {
  float: left; }

.#{$fa-css-prefix}-pull-right {
  float: right; }

.#{$fa-css-prefix} {
  &.#{$fa-css-prefix}-pull-left {
    margin-right: .3em; }

  &.#{$fa-css-prefix}-pull-right {
    margin-left: .3em; } }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.#{$fa-css-prefix} {
  &.pull-left {
    margin-right: .3em; }

  &.pull-right {
    margin-left: .3em; } }
