section.workout-overview {
  padding-bottom: 40px;
  .overview-navigator {
    height: 50vw;
    max-height: 50vh;
    // background-image: url("//cdn.shopify.com/s/files/1/1872/0733/t/4/assets/se-about-bg.jpg?10130944130337548536")
    background-color: $card-img-default-bg;
    position: relative;
    @extend .bg-cover;
    .overview-trainer-img {
      height: 100%; }
    .top-right {
      position: absolute;
      top: 20px;
      right: 20px; }
    .top-left {
      position: absolute;
      top: 20px;
      left: 20px; } }
  .overview-main {
    padding: 2rem;
    ul.icon-list {
      display: inline-block;
      > li {
        line-height: 1;
        &:not(:first-child) {
          margin-left: 5px; }
        svg {
          vertical-align: middle; } } } } }


section.completion-trophy, section.cardio {
  position: fixed;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding-top: $topbar-height;
  overflow-y: scroll;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  > .row.align-middle {
    min-height: 100%;
    color: $white; }
  .challenge-countdown-block {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    border-radius: $global-radius * 2;
    padding: 4px;
    width: 180px;
    .countdown-inner {
      background: $orange;
      border-radius: $global-radius * 2;
      color: $white;
      font-weight: bold;
      padding: 10px; } } }

section.cardio {
  padding: 100px 2rem 0;
  color: $black;
  text-align: left;
  select.liss-select {
    box-shadow: 0 3px 8px rgba(0,0,0,0.1);
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    height: 3rem; }
  .hiit-block {
    padding: 20px 10px 10px;
    box-shadow: 0 3px 8px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    border-radius: 4px;
    select {
      margin-bottom: 0; } } }

section.completion-trophy {
  background-color: $default-bg-gray;
  .row {
    .columns {
      h1 {
        font-size: 34px;
        line-height: 38px;
        margin-bottom: 5px; }
      h2 {
        font-size: 10px;
        line-height: 18px;
        color: $primary-color;
        margin-bottom: 0; }
      h3 {
        font-size: 15px;
        color: $gray-50;
        line-height: 22px; }
      .completion-trophy-image {
        @include flex;
        width: 220px;
        height: 220px;
        padding: 5px;
        border-radius: 50%;
        background-color: $white; } } }
  .workout-achievement-title {
    @include flex(center, space-between);
    margin-bottom: 12px;
    > h2, > p {
      margin-bottom: 0; }
    > p {
      @include flex; } }
  .summary-block {
    .summary-block-item {
      .progress-left {
        @include flex;
        width: 24px;
        > i {
          font-size: 24px;
          color: $primary-color; } }
      .progress-right {
        @include flex(center, space-between);
        flex: 1;
        margin-left: 20px; }
      .progress-detail {
        @include flex(flex-start, center, column); }
      .block-item-name {
        font-size: 17px;
        color: $gray-60; }
      .block-item-data {
        font-size: 15px; } } } }


.exercise {
  position: relative;
  .exercise-bg {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    top: -72vh;
    width: 205vw;
    height: 100vh;
    border-radius: 50%;
    background-color: $pink; }
  .exercise-container {
    display: block;
    max-width: 50vh;
    min-width: 320px;
    // max-width: 50vh
    // min-width: 250px
    padding-bottom: 165px;
    min-height: 600px;
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding-top: 10vh;
    .exercise-intro {
      color: $gray-60;
      margin-bottom: 15px;
      .intro-left {
        padding-left: 0;
        text-align: left;
        h2 {
          font-weight: 600;
          font-size: 17px; }
        h3 {
          font-size: 15px;
          color: $gray-50; } }
      .intro-right {
        padding-right: 0;
        text-align: right; }
      .countdown-timer {
        margin-bottom: 0;
        line-height: 1.1;
        font-size: 50px; } }
    .exercise-detail {
      background: $white;
      border-radius: $rebrand-global-radius $rebrand-global-radius 0 0;
      padding-top: 30px;
      padding-bottom: 5px;
      position: relative;
      margin-bottom: -5px;
      .alternative-workout {
        position: absolute;
        right: 10px;
        top: 10px; }
      > h2 {
        font-size: 28px; }
      > h3 {
        font-size: 20px;
        font-weight: bold;
        color: $gray-50; } }
    .exercise-rest {
      .activity-progress-bar-circle #progress-bar {
        position: relative;
        background: $white;
        border-radius: 10000rem; } }
    video.exercise-media {
      display: block; }
    .exercise-media {
      border-radius: 0 0 $rebrand-global-radius $rebrand-global-radius;
      @include box-shadow(0px 7px 10px rgba(0,0,0,0.08));
      padding: 50px 60px;
      max-height: unset;
      width: 100%;
      background: $white;
      > .table-block {
        min-height: 40vh; }
      /* style update for time counting */
      &.time-counting {
        width: 100%;
        .time-counting-content {
          position: relative;
          .time-counting-layer {
            width: 100%;
            padding-bottom: 80.89%;
            max-height: 100%; }
          > .time-counting-pane {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            max-height: 100%;
            max-width: 100%;
            display: flex;
            align-items: stretch;
            > #progress-bar {
              position: relative;
              padding: 20px;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              .timer-count {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%; }
              > svg {
                width: auto !important;
                height: 100%; } } } } } }
    .exercise-perside {
      min-height: 30vh;
      @include flex; }
    .exercise-weight-detail {
      @include flex(center, space-between);
      @include box-shadow(0px 7px 10px rgba(0,0,0,0.08));
      position: relative;
      background-color: $white;
      padding: 15px 25px 15px 65px;
      font-weight: 600;
      font-size: 17px;
      border-radius: 0 0 $rebrand-global-radius $rebrand-global-radius;
      border-top: 1px solid $gray-0;
      cursor: pointer;
      .detail-left {
        @include flex;
        .exercise-sets {
          width: 2em;
          height: 2em;
          color: $gray-5;
          line-height: 1.7em;
          font-size: 14px;
          text-align: center;
          border: 0.2em solid $gray-5;
          border-radius: 50%;
          margin-right: 20px; } }
      .detail-middle {
        color: $gray-60; }
      .detail-right {
        > i {
          margin-left: 35px;
          color: $gray-10; }
        &.disabled {
          color: $gray-5; } }
      .one-rm-alert {
        position: absolute;
        top: 110%;
        left: -2%;
        width: 100%;
        padding: 15px 10px;
        color: $white;
        background-color: #100030;
        border-radius: $global-radius;
        @include box-shadow(0px 7px 10px rgba(0,0,0,0.08));
        z-index: 2000;
        .alert-close {
          position: absolute;
          top: 10px;
          right: 10px;
          svg {
            width: 15px;
            height: 15px; } }
        .tooltip-arrow-up {
          position: absolute;
          top: -20px;
          left: 52%;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom: 10px solid #100030; }
        > h3 {
          font-size: 14px; }
        > p {
          color: $white;
          margin-bottom: 0;
          font-size: 12px; } } }
    .exercise-reward, .exercise-rest {
      margin-top: 7vh;
      &.exercise-rest-card {
        margin-top: 0;
        border-radius: $rebrand-global-radius;
        @include box-shadow(0px 7px 10px rgba(0,0,0,0.08));
        background: $white;
        > .exercise-break {
          color: $gray-60;
          padding-top: 30px; }
        > .activity-progress-bar-wrapper {
          padding: 50px 60px 80px; } }
      > .exercise-break {
        color: $white;
        font-size: 28px;
        text-align: center;
        margin-bottom: 20px;
        padding-top: 15px; } }
    .exercise-reward-circle {
      height: 30vh;
      width: 30vh;
      padding: 80px;
      border-radius: 10000rem;
      border: 8px solid;
      border-color: $white;
      margin: 0 auto;
      position: relative;
      svg {
        height: 15vh;
        width: 15vh;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%; } }
    .exercise-pause {
      display: block;
      height: auto;
      border-radius: $global-radius;
      margin-top: 8vh;
      > .row {
        margin: 0 -15px;
        .columns:not(:last-child) {
          .media {
            margin: 0;
            .media-left {
              display: block;
              margin-bottom: 20px; } } }
        .button {
          display: flex;
          align-items: center;
          font-weight: 600;
          border-radius: $global-radius;
          &.workout-pause-button {
            width: 100%;
            padding: 0 20px;
            min-height: 60px;
            margin-bottom: 1rem;
            text-align: left;
            @include box-shadow(0 3px 8px rgba(0,0,0,0.15));
            .button-icon {
              display: inline-block;
              border-radius: $global-radius;
              padding: 0;
              margin-right: 1rem;
              &.restart-icon {
                height: 34px;
                width: 34px;
                border-radius: 50%;
                border: 2px solid $gray-light;
                @include flex;
                i {
                  font-size: 16px;
                  @include transform(scaleX(-1) rotate(-45deg));
                  margin-top: 4px; } }
              i {
                color: $gray-light;
                font-size: 34px;
                margin-top: 4px; } }
            .button-text {
              font-size: 16px;
              font-weight: 500; }
            &[disabled] {
              background: $gray-lighter;
              opacity: 0.8;
              box-shadow: none;
              border: none;
              cursor: default !important;
              &:hover {
                color: $oil; } } }
          &.resume-button {
            padding-top: 0;
            padding-bottom: 0;
            min-height: 60px;
            font-size: 16px !important;
            i {
              margin: 2px 22px 0 12px; } } } } }
    .exercise-weight-input {
      margin-top: 3vh;
      .reps-completed {
        background: $white;
        border-radius: $global-radius;
        padding: 25px 20px;
        margin-bottom: 20px;
        .rep-control {
          height: 28px;
          min-width: 40px;
          padding: 0;
          margin: 0;
          border-radius: $global-radius;
          color: $white;
          background: $pink;
          text-align: center;
          font-weight: bold;
          font-size: 20px !important;
          display: inline-block;
          line-height: 1.3; } }
      .weight-lifted {
        background: $white;
        border-radius: $global-radius;
        @include box-shadow(0 3px 8px rgba(0,0,0,0.1));
        overflow: hidden;
        > .media {
          padding: 25px 20px;
          margin: 0;
          .narrow-spacing {
            letter-spacing: -1.5px; } }
        .row.num-pad {
          overflow: hidden;
          .columns {
            border-top: 1px solid $gray-lighter;
            &:nth-child(3n+2) {
              border-left: 1px solid $gray-lighter;
              border-right: 1px solid $gray-lighter; }
            .pad-button {
              padding: 25px 20px;
              font-size: 20px !important;
              width: 100%;
              font-weight: bold;
              display: block;
              margin: 0;
              border-radius: 0;
              &:hover {
                background: lighten($gray-lighter, 3%);
                transform: none;
                @include box-shadow(none); }
              &[disabled] {
                background: $white; } } } } } }
    // Transition timer overlay style
    .transition-timer-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      z-index: 200;
      @include flex;
      .transition-timer-cover {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        border-radius: $rebrand-global-radius;
        background-color: $white;
        opacity: 0.6; }
      .activity-progress-bar-wrapper {
        z-index: 300;
        .activity-progress-bar-circle {
          width: 25vh;
          height: 25vh;
          .timer-count {
            font-size: 52px;
            font-weight: bold; } } } }
    /* long form content section video style */
    &.with-section-video {
      position: relative;
      padding: $column-gutter;
      max-width: 100vw;
      width: 100%;
      min-height: 100vh;
      @include flex(center, center, column);
      .section-video-container {
        max-width: $section-video-max-width;
        width: 100%;
        .section-video-container-footer {
          min-height: 50px;
          display: flex;
          align-items: center;
          padding: 0 $column-gutter;
          // +box-shadow(0 0 8px rgba(0,0,0,0.1))
          border-bottom-left-radius: $global-radius;
          border-bottom-right-radius: $global-radius;
          .button {
            margin: 0; }
          .section-video-container-footer-left {
            flex: 1;
            margin-right: $column-gutter; } }
        .section-video-wrapper {
          position: relative;
          margin: auto;
          border-radius: $global-radius;
          overflow: hidden;
          .video-inner-title {
            position: absolute;
            bottom: 30px;
            left: 50px;
            z-index: 11; }
          .section-video-layer {
            position: relative;
            z-index: 10;
            &.full-screen-without-control {
              // video::-webkit-media-controls
              //   display: none
              video::-webkit-media-controls-play-button {
                display: none; }
              video::-webkit-media-controls-current-time-display, video::-webkit-media-controls-time-remaining-display, video::-webkit-media-controls-volume-control-container {
                display: none; }
              video::-webkit-media-controls-timeline {
                display: none; }
              video::-internal-media-controls-overflow-button {
                display: none; } } }
          .section-video-setting-layer {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 11;
            background-color: rgba(0,0,0,0.7);
            padding: 40px 40px 30px 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .setting-row-top {
              display: flex;
              align-items: center;
              .setting-row-top-left {
                flex: 1;
                .video-title {
                  margin: 0 20px 0 0; } }
              .setting-row-top-right {
                display: flex;
                align-items: center;
                .video-setting-button:last-child {
                  margin-right: 0; } } }
            .setting-row-middle {
              display: flex;
              align-items: center;
              justify-content: space-around; }
            .setting-row-bottom {
              display: flex;
              align-items: flex-end;
              .setting-row-bottom-left {
                flex: 1;
                margin-right: 20px;
                padding-bottom: 3px; }
              .video-progressbar-wrapper {
                display: flex;
                flex-direction: column;
                flex: 1;
                .progress-heading {
                  margin-bottom: 4px;
                  .progress-title {
                    font-size: 14px;
                    color: $gray-light; } }
                .video-progressbar-group {
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  .video-progressbar-row {
                    display: flex;
                    align-items: center;
                    > .video-progressbar {
                      flex: 1;
                      margin-right: 6px;
                      &:last-child {
                        margin-right: 0; } } }
                  // .video-slider-row
                  //   position: absolute
                  //   top: 0
                  //   left: 0
                  //   width: 100%
                  .range-input-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%; } }
                .range-input-wrapper {
                  &.without-thumb {
                    > input.range-input::-webkit-slider-thumb {
                      display: none; } }
                  input.range-input {
                    @include appearance(none);
                    display: block;
                    outline: none;
                    border: none;
                    line-height: 1;
                    width: 100%;
                    height: 6px;
                    margin-top: 6px;
                    background: $transparent;
                    &::-webkit-slider-runnable-track {
                      max-height: 0; }
                    &::-webkit-slider-thumb {
                      @include appearance(none);
                      display: block;
                      height: 15px;
                      width: 15px;
                      border-radius: 50%;
                      background: $white;
                      @include box-shadow(0 0 5px rgba(0,0,0,0.4));
                      @include transition(all 0.2s ease);
                      margin-top: -9px;
                      &:hover {
                        @include transform(scale(1.5)); } } } }
                .video-progressbar {
                  width: 100%;
                  height: 4px;
                  outline: none;
                  margin: 6px 0;
                  background-color: rgba(154, 154, 155, 0.8);
                  .progress-meter {
                    position: relative;
                    .progress-bar-indicator {
                      position: absolute;
                      right: 0;
                      top: 50%;
                      @include transition(all 0.2s ease);
                      @include transform(translate(50%, -50%));
                      height: 18px;
                      width: 18px;
                      background-color: $white;
                      border-radius: 50%;
                      @include box-shadow(0 0 5px rgba(0,0,0,0.4));
                      cursor: pointer; } }
                  &:hover .progress-meter .progress-bar-indicator {
                    @include transform(translate(50%, -50%) scale(1.5)); } }
                // .video-slider
                //   margin: 6px 0
                //   border-radius: 5px
                //   height: 4px
                //   background-color: $transparent
                //   .slider-handle
                //     outline: none
                //     height: 15px
                //     width: 15px
                //     border-radius: 50%
                //     background-color: $white
                //     +box-shadow(0 0 5px rgba(0,0,0,0.4))
                //     +transition(all 0.2s ease)
                //     +transform(translate(-65% ,-50%))
                //     &:hover
                //       +transform(translate(-65% ,-50%) scale(1.5))
                //   .slider-fill
 } }                //     display: none
            .setting-row-notice-pane {
              margin: auto 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              .pane-content {
                width: 100%;
                color: $white;
                *:last-child {
                  margin-bottom: 0; }
                margin-bottom: 30px; }
              .pane-actions {
                max-width: 260px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                > .button {
                  width: 100%; }
                > .button + .text-link {
                  margin-top: 8px; }
                > *:last-child {
                  margin-bottom: 0; } } } } } } } }

  .exercise-ready-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    max-height: 72vh; } }

.activity-progress-bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  .activity-progress-bar-circle {
    margin: 0;
    width: 70px;
    height: 70px;
    position: relative;
    &.large {
      width: 30vh;
      height: 30vh;
      .timer-count {
        font-size: 100px; } }
    #progress-bar, #transition-timer-progress-bar {
      position: relative;
      .timer-count {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%; } } } }

.workout-actions {
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 20px 0;
  .action-container {
    @include flex;
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
    text-align: center; }
  .control-button {
    padding: 12px 15px;
    margin-bottom: 0;
    @include box-shadow(-4px -3px 8px rgba(0,0, 0,0.1));
    i {
      font-size: 20px; }
    &.large {
      padding: 18px 21px;
      margin: 0 30px;
      i {
        font-size: 32px; } }
    &.img-button {
      padding: 0;
      @include flex;
      box-shadow: none;
      margin: 0 30px;
      svg {
        height: 70px;
        width: auto; }
      &:hover {
        @include hover_action; } } } }

.timer {
  margin: 0;
  line-height: 1;
  font-size: 6rem;
  &.small {
    font-size: 4rem; }
  &.medium {
    font-size: 7rem; }
  &.large {
    font-size: 9rem; } }

.circular-progress-bar {
  position: relative;
  max-width: 500px;
  width: 90%;
  margin: 2rem auto;
  .meta {
    @include middle;
    * {
      margin: 0; } }
  #add-btn, #minus-btn {
    @include transform(translateX(-50%));
    position: absolute;
    left: 50%; }
  #add-btn {
    margin-top: rem-calc(-20);
    top: 10%; }
  #minus-btn {
    margin-bottom: rem-calc(-20);
    bottom: 10%; }
  .meta {
    .challenge {
      color: $orange; }
    .resistance {
      color: $pink; } } }

.ember-progress-bar {
  margin-bottom: rem-calc(-5);
  svg {
    height: rem-calc(5);
    * {
      stroke: $pink; } } }

span.label.set-count {
  border-radius: 100%;
  height: 25px;
  width: 25px;
  padding: 4px 0;
  background: $white;
  font-size: 16px;
  margin-left: 5px;
  &.pink {
    color: $pink;
    border: 1px solid $pink;
    &.active {
      background: $pink;
      color: $white; } }
  &.blue {
    color: $blue;
    border: 1px solid $blue;
    &.active {
      background: $blue;
      color: $white; } }
  &.orange {
    color: $orange;
    border: 1px solid $orange;
    &.active {
      background: $orange;
      color: $white; } } }

//more challenges page
#more-challenges {
  section {
    padding: 2rem; }
  ul#challenge-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
    border: none !important;
    > li.tabs-title {
      flex: 1;
      border-bottom: 3px solid lighten($border-color, 10%);
      &.is-active {
        border-color: $pink;
        > a {
          color: $pink; } }
      > a {
        text-align: center;
        color: $gray-light;
        font-weight: bold; } } } }
.challenge-other-programs {
  padding: 0 20px; }

.week-rollover {
  section {
    padding: 2rem; } }

/* long form and rebrand workout overview content classes */
.workout-overview {
  .workout-overview-name {
    font-size: 34px; }
  .workout-overview-info-row {
    margin-bottom: $column-gutter;
    &:last-child {
      margin-bottom: 0; }
    &.fix-width {
      width: 600px; }
    h1, h2, h3, h4, h5, p {
      margin-bottom: 0.25rem;
      &:last-child {
        margin-bottom: 0; }
      &:empty {
        display: none; } }
    .sub-title-top {
      @include flex(center, flex-start);
      margin-bottom: 0.25rem;
      h5 {
        margin: 0; }
      .pane-label-tag.block {
        margin-left: 8px; } }
    .dotted-title {
      @include flex(flex-start, flex-start);
      .title-dot {
        font-size: 8px;
        margin-right: $column-gutter/4;
        margin-top: 7px; } }
    .card-group-v {
      max-width: 600px;
      width: 100%;
      margin-top: $column-gutter/2;
      > .card {
        &:hover {
          @include transform(scale(1.01)); }
        &:last-child {
          margin-bottom: 0; } } }
    .detail-paragraph {
      color: $gray-50;
      font-weight: 500;
      font-size: 15px;
      p {
        margin-bottom: 0; } }
    /* rebrand workout-overview-info-row */
    &.list-row {
      > .card-group-v {
        // .card
        //   box-shadow: none
        //   padding: 0 0 8px 0
        //   margin-bottom: 8px
        //   border-bottom: 1px solid $border-color
        //   border-radius: 0
        //   .img-color-layer
        //     border-radius: $rebrand-global-radius
        //   &:last-child
        //     margin-bottom: 0
 }        //     border: none
      &.challenges {
        margin-bottom: 1rem;
        > .card-group-v {
          max-width: 100%; } } } }

  .card {
    &.image-text-h-card {
      padding: $column-gutter/4 $column-gutter/2 $column-gutter/4 $column-gutter/4;
      border-radius: $rebrand-global-radius-heavy;
      // +box-shadow(0 1px 8px 2px rgba(0, 0, 0, 0.1))
      border: 1px solid $gray-5;
      display: flex;
      align-items: stretch;
      flex-direction: row;
      .card-left {
        @include flex;
        height: 80px;
        width: 96px;
        margin-right: $column-gutter/2;
        &.timer-card-left {
          position: relative;
          .timer-circle,.timer-number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); } }
        .category-img {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: $rebrand-global-radius;
          @include background; }
        .img-color-layer {
          background-color: $gray-60;
          opacity: 0.1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 14px; } }
      .card-right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text-content {
          h4 {
            font-size: 17px; }
          .tile-detail {
            color: $gray-40;
            font-size: 15px; } }
        .card-status {
          margin-left: auto;
          margin-right: 10px; } } }
    &:hover {
      color: initial; } } }




@media #{$small-only} {
  .exercise {
    .exercise-bg {
      width: 290vw;
      top: -85vh; }
    .exercise-container .exercise-pause {
      margin-top: 20px; } }
  .overlay-custom.cardio-pause {
    .exercise-pause {
      > .row .columns {
        .button {
          margin-bottom: 10px; }
        .media {
          margin: 0;
          .media-left {
            display: table-cell !important;
            margin-bottom: 0 !important; }
          .media-body {
            padding-left: 20px !important; } } } } }
  section.workout-circuit .exercise {
    .exercise-container {
      padding-top: 15px;
      .exercise-detail {
        padding-top: 20px;
        h1 {
          font-size: 1.3rem; } }
      .exercise-rest, .exercise-reward-circle {
        margin: 0 auto;
        > .exercise-break {
          font-size: 24px;
          margin-bottom: 20px; } }
      .exercise-reward-circle {
        height: 250px;
        width: 250px;
        padding: 40px; }
      .activity-progress-bar-circle {
        &.large {
          height: 250px;
          width: 250px; } }
      .exercise-reward {
        margin-top: 0;
        > h1 {
          font-size: 24px;
          margin-bottom: 20px; }
        svg {
          height: 150px;
          width: 150px; } }
      .exercise-rest {
        .rest-countdown {
          line-height: 180px;
          font-size: 70px; } }
      .exercise-pause {
        > .row .columns {
          .button {
            margin-bottom: 10px;
            &.workout-pause-button {
              padding-top: 0;
              padding-bottom: 0;
              min-height: 60px;
              .button-icon {
                i {
                  font-size: 24px; }
                &.restart-icon {
                  height: 24px;
                  width: 24px;
                  i {
                    font-size: 12px;
                    margin-top: 1px; } } } }
            &.resume-button {
              min-height: 60px;
              i {
                margin-left: 4px; } } }
          .media {
            margin: 0;
            .media-left {
              display: table-cell;
              margin-bottom: 0; }
            .media-body {
              padding-left: 20px; } } } } } }
  .workout-actions {
    // position: static
    // padding: 0
    .control-button {
      padding: 10px 13px;
      i {
        font-size: 14px; }
      &.large {
        padding: 15px 18px;
        margin: 0 15px;
        i {
          font-size: 24px; } } } }

  .circular-progress-bar {
    margin: 1rem auto; }
  .timer {
    font-size: 5rem;
    &.small {
      font-size: 3rem; }
    &.medium {
      font-size: 6rem; }
    &.large {
      font-size: 8rem; } }
  #more-challenges {
    section {
      padding: 20px; } }

  /* long form and rebrand workout overview content classes */
  .workout-overview {
    .workout-overview-info-row {
      &.fix-width {
        width: 100%; }
      .info-row-title {
        h2 {
          margin-bottom: 4px; } } } } }

