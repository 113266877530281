.lead {
  color: $gray; }
.caps {
  text-transform: uppercase; }
.capitalize {
  text-transform: capitalize; }
.lowercase {
  text-transform: lowercase; }
.font-montserrat {
  font-family: 'Montserrat', sans-serif;
  line-height: 1; }
.font-open-sans {
  font-family: 'Open Sans', sans-serif; }
.font-body {
  font-family: $body-font-family; }
.font-condensed {
  line-height: 1.3 !important; }
.font-more-condensed {
  line-height: 1.2 !important; }
.font-extra-condensed {
  line-height: 1 !important; }
.text-spanned {
  letter-spacing: 2px; }
.text-nowrap {
  white-space: nowrap; }
.text-pre-line {
  white-space: pre-line; }
.text-break {
  word-break: break-all; }
.text-break-word {
  word-break: break-word; }
.ellipsis {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap; }
.strike {
  text-decoration: line-through; }
.underline {
  text-decoration: underline; }
// Font Weights
.font-thin {
  font-weight: 100 !important; }
.font-light {
  font-weight: 300 !important; }
.font-normal {
  font-weight: 400 !important; }
.font-medium {
  font-weight: 500 !important; }
.font-semi-bold {
  font-weight: 600 !important; }
.font-bold {
  font-weight: bold !important; }


[class*="text-hover-"] {
  @extend .transit;
  cursor: pointer; }

// Font Colors for buttons
@each $name, $color in $color-map {
  .text-#{$name} {
    color: $color !important;
    svg {
      fill: $color; } }
  .text-hover-#{$name}:hover {
    color: $color !important; } }


