// Animation
@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "}; } }

  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations; }

// Keyframes
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content; }

  @-moz-keyframes #{$animationName} {
    @content; }

  @-o-keyframes #{$animationName} {
    @content; }

  @keyframes #{$animationName} {
    @content; } }

.fadeout {
  @include transition(opacity 0.5s ease-in-out);
  @include opacity(0); }

.fadein {
  @include transition(opacity 0.5s ease-in-out);
  @include opacity(1); }

.transit {
  @include transition(all 0.15s ease-in-out); }

.hover-scale:hover {
  @include transform(scale(1.1)); }
