//sassc
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  transition: $transition; }
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  transform: $transform; }
@mixin box-shadow($box-shadow) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  -ms-box-shadow: $box-shadow;
  box-shadow: $box-shadow; }
@mixin opacity($opacity) {
  zoom: 1;
  filter: alpha(opacity=$opacity*100);
  opacity: $opacity; }
@mixin filter($filter) {
  -webkit-filter: $filter;
  -moz-filter: $filter;
  -ms-filter: $filter;
  filter: $filter; }
