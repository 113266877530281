.media {
  &:first-child {
    margin-top: 20px; }
  .media-body {
    width: 10000px; }
  .media-left,
  .media-right,
  .media-body {
    display: table-cell;
    vertical-align: middle; } }
section.planner {
  .day-item {
    width: 60px;
    height: 70px;
    display: inline-block;
    position: relative;
    .day-item-content {
      font-size: 16px;
      font-weight: bold;
      position: absolute;
      &.align-top {
        top: 0;
        left: 50%;
        transform: translateX(-50%); }
      &.align-middle {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .today-item {
        height: 30px;
        width: 30px;
        display: inline-block;
        border-radius: 50%;
        padding-top: 3px; } }
    .day-dot {
      height: 5px;
      width: 5px;
      display: inline-block;
      border-radius: 100%; } }
  .stacked-links.rehab-list {
    @include box-shadow(none);
    li.list-group-item {
      border-radius: 6px;
      > a {
        padding: 0; } }
    .rehab-card {
      .rehab-card-body {
        // background-image: url("https://cdn.shopify.com/s/files/1/1872/0733/t/4/assets/se-about-bg.jpg?10130944130337548536")
        background-color: $card-img-default-bg;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; }
      .rehab-card-footer {
        padding: 20px; } } }
  .week-row {
    .day-item {
      color: black;
      border-radius: 6px;
      &:hover {
        color: $pink; } } }
  .timeline-item {
    .date-bar {
      padding: 16px 20px; }
    > a:last-child .event-card {
      border: none;
      .first-button, .second-button {
        border: none; } }
    > .event-card:last-child {
      border: none;
      .first-button, .second-button {
        border: none; } }
    .first-button, .second-button {
      position: absolute;
      top: 0;
      height: 100%;
      background: $gray;
      width: 100px;
      border-bottom: 1px solid $border-color;
      > .relative {
        height: 100%; }
      .align-middle {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute; } }
    .first-button {
      right: -100px; }
    .second-button {
      right: -200px; }
    .delete-button {
      border-left: 1px solid $border-color; } }

  .event-card {
    padding: 25px 20px;
    position: relative;
    border-bottom: 1px solid $border-color;
    margin-left: 0;
    transition: all 0.2s linear;
    &.two-button-card:not(.card-no-hover):hover {
      margin-right: 200px; }
    &.one-button-card:not(.card-no-hover):hover {
      margin-right: 100px; }
    h1, h2, h3, h4, h5 {
      margin-bottom: 6px; }
    .complete-icon {
      position: absolute;
      top: 25px;
      right: 20px; }
    .event-coach {
      height: 30px;
      width: 30px;
      border-radius: 100%;
      background-color: $pink;
      background-position: top; }
    &.incompleted:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.5);
      border-radius: 6px; } }
  .week-selector {
    padding-top: 8px;
    z-index: 1;
    // top: 60px
    // left: 0
    position: relative;
    width: 100%;
    background: $white;
    @include box-shadow(0 3px 8px rgba(0,0,0,0.1));
    &.sticky-week-selector {
      position: fixed;
      z-index: 10000;
      top: 0;
      left: 0; } }
  #planner-carousel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    .date-selector {
      flex: 1;
      text-align: center;
      letter-spacing: 1px;
      margin: 0 20px;
      span.label.round {
        color: $gray;
        background: transparent;
        border-radius: 100%;
        border: 1px solid transparent;
        height: 25px;
        width: 25px;
        padding: 5px 0 !important; } } }
  .timeline-control {
    text-align: left;
    .today-button > * {
     margin-top: -10px;
     display: inline-block; } }
  .dropdown-switch {
    width: auto !important;
    float: right;
    margin-top: 10px;
    > li > a {
      padding-right: 0 !important; }
    > li > a:after {
      display: none !important;
      padding: 0; }
    .is-dropdown-submenu {
      z-index: 2;
      min-width: 320px !important;
      @include box-shadow(0px 3px 10px rgba(0,0,0,0.2));
      border-color: transparent !important;
      padding: 10px; } }
  span.label.activity {
    background: $gray-bg;
    border-radius: 35px;
    display: table;
    position: relative;
    margin: 0 10px;
    cursor: pointer;
    img.img-circle {
      height: 50px;
      width: 50px;
      display: table-cell;
      vertical-align: middle; }
    > span {
      display: table-cell;
      vertical-align: middle; }
    > svg {
      position: absolute;
      top: 0;
      right: -5px; } }
  .row.date-view {
    border-bottom: 1px solid $border-color;
    padding: 20px 10%;
    position: relative;
    &:before {
      content: " ";
      position: absolute;
      @include background(linear-gradient(to right, rgba(255, 255, 255, 0) , white));
      top: 20px;
      right: 10%;
      height: 62px;
      width: 3%;
      z-index: 1; }
    &:after {
      content: " ";
      position: absolute;
      @include background(linear-gradient(to left, rgba(255, 255, 255, 0) , white));
      top: 20px;
      left: 10%;
      height: 62px;
      width: 3%;
      z-index: 1; }
    span.days {
      z-index: 99;
      position: absolute;
      top: 25%;
      left: 5%; }
    .add-activity {
      position: absolute;
      top: 40%;
      right: 5%; }
    &:nth-child(2) {
      border-top: 1px solid $border-color; }
    > .columns {
      line-height: 100% !important;
      overflow-x: scroll;
      overflow-y: hidden;
      height: 62px;
      position: relative;
      &::-webkit-scrollbar {
        display: none; } } } }

@media #{$small-only} {
  section.planner {
    padding: 0 0 50px;
    .event-card {
      .mobile-controls {
        position: relative;
        z-index: 10;
        white-space: nowrap;
        .button {
          margin: 0;
          padding: 6px; } }
      &:hover {
        margin-right: 0 !important; } }
    .day-item {
      width: 40px; }
    .week-selector {
      font-size: 20px;
      .previous-week {
        left: -15%; }
      .next-week {
        right: -15%; } }
    .dropdown-switch {
      position: static;
      .media-right {
        padding-top: 10px; } }
    .row.date-view {
      padding: 20px 12%;
      .columns {
        padding: 0; }
      &:before {
        right: 12%; }
      &:after {
        left: 12%; }
      span.days {
        position: absolute;
        top: 25%;
        left: 0; }
      .add-activity {
        position: absolute;
        top: 40%;
        right: 0; } } } }
