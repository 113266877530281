// Variables
// --------------------------

$fa-sass-asset-helper: false !default;

$fa-font-path: if($fa-sass-asset-helper, "font-awesome/", "fonts/font-awesome/") !default;
$fa-font-name: "fontawesome-webfont" !default;

$fa-font-size-base: 14px !default;
$fa-line-height-base: 1 !default;
$fa-css-prefix: fa !default;
$fa-version: "4.7.0" !default;
$fa-border-color: #eee !default;
$fa-inverse: #fff !default;
$fa-li-width: 30em / 14 !default;

$fa-var-500px: "";
$fa-var-address-book: "";
$fa-var-address-book-o: "";
$fa-var-address-card: "";
$fa-var-address-card-o: "";
$fa-var-adjust: "";
$fa-var-adn: "";
$fa-var-align-center: "";
$fa-var-align-justify: "";
$fa-var-align-left: "";
$fa-var-align-right: "";
$fa-var-amazon: "";
$fa-var-ambulance: "";
$fa-var-american-sign-language-interpreting: "";
$fa-var-anchor: "";
$fa-var-android: "";
$fa-var-angellist: "";
$fa-var-angle-double-down: "";
$fa-var-angle-double-left: "";
$fa-var-angle-double-right: "";
$fa-var-angle-double-up: "";
$fa-var-angle-down: "";
$fa-var-angle-left: "";
$fa-var-angle-right: "";
$fa-var-angle-up: "";
$fa-var-apple: "";
$fa-var-archive: "";
$fa-var-area-chart: "";
$fa-var-arrow-circle-down: "";
$fa-var-arrow-circle-left: "";
$fa-var-arrow-circle-o-down: "";
$fa-var-arrow-circle-o-left: "";
$fa-var-arrow-circle-o-right: "";
$fa-var-arrow-circle-o-up: "";
$fa-var-arrow-circle-right: "";
$fa-var-arrow-circle-up: "";
$fa-var-arrow-down: "";
$fa-var-arrow-left: "";
$fa-var-arrow-right: "";
$fa-var-arrow-up: "";
$fa-var-arrows: "";
$fa-var-arrows-alt: "";
$fa-var-arrows-h: "";
$fa-var-arrows-v: "";
$fa-var-asl-interpreting: "";
$fa-var-assistive-listening-systems: "";
$fa-var-asterisk: "";
$fa-var-at: "";
$fa-var-audio-description: "";
$fa-var-automobile: "";
$fa-var-backward: "";
$fa-var-balance-scale: "";
$fa-var-ban: "";
$fa-var-bandcamp: "";
$fa-var-bank: "";
$fa-var-bar-chart: "";
$fa-var-bar-chart-o: "";
$fa-var-barcode: "";
$fa-var-bars: "";
$fa-var-bath: "";
$fa-var-bathtub: "";
$fa-var-battery: "";
$fa-var-battery-0: "";
$fa-var-battery-1: "";
$fa-var-battery-2: "";
$fa-var-battery-3: "";
$fa-var-battery-4: "";
$fa-var-battery-empty: "";
$fa-var-battery-full: "";
$fa-var-battery-half: "";
$fa-var-battery-quarter: "";
$fa-var-battery-three-quarters: "";
$fa-var-bed: "";
$fa-var-beer: "";
$fa-var-behance: "";
$fa-var-behance-square: "";
$fa-var-bell: "";
$fa-var-bell-o: "";
$fa-var-bell-slash: "";
$fa-var-bell-slash-o: "";
$fa-var-bicycle: "";
$fa-var-binoculars: "";
$fa-var-birthday-cake: "";
$fa-var-bitbucket: "";
$fa-var-bitbucket-square: "";
$fa-var-bitcoin: "";
$fa-var-black-tie: "";
$fa-var-blind: "";
$fa-var-bluetooth: "";
$fa-var-bluetooth-b: "";
$fa-var-bold: "";
$fa-var-bolt: "";
$fa-var-bomb: "";
$fa-var-book: "";
$fa-var-bookmark: "";
$fa-var-bookmark-o: "";
$fa-var-braille: "";
$fa-var-briefcase: "";
$fa-var-btc: "";
$fa-var-bug: "";
$fa-var-building: "";
$fa-var-building-o: "";
$fa-var-bullhorn: "";
$fa-var-bullseye: "";
$fa-var-bus: "";
$fa-var-buysellads: "";
$fa-var-cab: "";
$fa-var-calculator: "";
$fa-var-calendar: "";
$fa-var-calendar-check-o: "";
$fa-var-calendar-minus-o: "";
$fa-var-calendar-o: "";
$fa-var-calendar-plus-o: "";
$fa-var-calendar-times-o: "";
$fa-var-camera: "";
$fa-var-camera-retro: "";
$fa-var-car: "";
$fa-var-caret-down: "";
$fa-var-caret-left: "";
$fa-var-caret-right: "";
$fa-var-caret-square-o-down: "";
$fa-var-caret-square-o-left: "";
$fa-var-caret-square-o-right: "";
$fa-var-caret-square-o-up: "";
$fa-var-caret-up: "";
$fa-var-cart-arrow-down: "";
$fa-var-cart-plus: "";
$fa-var-cc: "";
$fa-var-cc-amex: "";
$fa-var-cc-diners-club: "";
$fa-var-cc-discover: "";
$fa-var-cc-jcb: "";
$fa-var-cc-mastercard: "";
$fa-var-cc-paypal: "";
$fa-var-cc-stripe: "";
$fa-var-cc-visa: "";
$fa-var-certificate: "";
$fa-var-chain: "";
$fa-var-chain-broken: "";
$fa-var-check: "";
$fa-var-check-circle: "";
$fa-var-check-circle-o: "";
$fa-var-check-square: "";
$fa-var-check-square-o: "";
$fa-var-chevron-circle-down: "";
$fa-var-chevron-circle-left: "";
$fa-var-chevron-circle-right: "";
$fa-var-chevron-circle-up: "";
$fa-var-chevron-down: "";
$fa-var-chevron-left: "";
$fa-var-chevron-right: "";
$fa-var-chevron-up: "";
$fa-var-child: "";
$fa-var-chrome: "";
$fa-var-circle: "";
$fa-var-circle-o: "";
$fa-var-circle-o-notch: "";
$fa-var-circle-thin: "";
$fa-var-clipboard: "";
$fa-var-clock-o: "";
$fa-var-clone: "";
$fa-var-close: "";
$fa-var-cloud: "";
$fa-var-cloud-download: "";
$fa-var-cloud-upload: "";
$fa-var-cny: "";
$fa-var-code: "";
$fa-var-code-fork: "";
$fa-var-codepen: "";
$fa-var-codiepie: "";
$fa-var-coffee: "";
$fa-var-cog: "";
$fa-var-cogs: "";
$fa-var-columns: "";
$fa-var-comment: "";
$fa-var-comment-o: "";
$fa-var-commenting: "";
$fa-var-commenting-o: "";
$fa-var-comments: "";
$fa-var-comments-o: "";
$fa-var-compass: "";
$fa-var-compress: "";
$fa-var-connectdevelop: "";
$fa-var-contao: "";
$fa-var-copy: "";
$fa-var-copyright: "";
$fa-var-creative-commons: "";
$fa-var-credit-card: "";
$fa-var-credit-card-alt: "";
$fa-var-crop: "";
$fa-var-crosshairs: "";
$fa-var-css3: "";
$fa-var-cube: "";
$fa-var-cubes: "";
$fa-var-cut: "";
$fa-var-cutlery: "";
$fa-var-dashboard: "";
$fa-var-dashcube: "";
$fa-var-database: "";
$fa-var-deaf: "";
$fa-var-deafness: "";
$fa-var-dedent: "";
$fa-var-delicious: "";
$fa-var-desktop: "";
$fa-var-deviantart: "";
$fa-var-diamond: "";
$fa-var-digg: "";
$fa-var-dollar: "";
$fa-var-dot-circle-o: "";
$fa-var-download: "";
$fa-var-dribbble: "";
$fa-var-drivers-license: "";
$fa-var-drivers-license-o: "";
$fa-var-dropbox: "";
$fa-var-drupal: "";
$fa-var-edge: "";
$fa-var-edit: "";
$fa-var-eercast: "";
$fa-var-eject: "";
$fa-var-ellipsis-h: "";
$fa-var-ellipsis-v: "";
$fa-var-empire: "";
$fa-var-envelope: "";
$fa-var-envelope-o: "";
$fa-var-envelope-open: "";
$fa-var-envelope-open-o: "";
$fa-var-envelope-square: "";
$fa-var-envira: "";
$fa-var-eraser: "";
$fa-var-etsy: "";
$fa-var-eur: "";
$fa-var-euro: "";
$fa-var-exchange: "";
$fa-var-exclamation: "";
$fa-var-exclamation-circle: "";
$fa-var-exclamation-triangle: "";
$fa-var-expand: "";
$fa-var-expeditedssl: "";
$fa-var-external-link: "";
$fa-var-external-link-square: "";
$fa-var-eye: "";
$fa-var-eye-slash: "";
$fa-var-eyedropper: "";
$fa-var-fa: "";
$fa-var-facebook: "";
$fa-var-facebook-f: "";
$fa-var-facebook-official: "";
$fa-var-facebook-square: "";
$fa-var-fast-backward: "";
$fa-var-fast-forward: "";
$fa-var-fax: "";
$fa-var-feed: "";
$fa-var-female: "";
$fa-var-fighter-jet: "";
$fa-var-file: "";
$fa-var-file-archive-o: "";
$fa-var-file-audio-o: "";
$fa-var-file-code-o: "";
$fa-var-file-excel-o: "";
$fa-var-file-image-o: "";
$fa-var-file-movie-o: "";
$fa-var-file-o: "";
$fa-var-file-pdf-o: "";
$fa-var-file-photo-o: "";
$fa-var-file-picture-o: "";
$fa-var-file-powerpoint-o: "";
$fa-var-file-sound-o: "";
$fa-var-file-text: "";
$fa-var-file-text-o: "";
$fa-var-file-video-o: "";
$fa-var-file-word-o: "";
$fa-var-file-zip-o: "";
$fa-var-files-o: "";
$fa-var-film: "";
$fa-var-filter: "";
$fa-var-fire: "";
$fa-var-fire-extinguisher: "";
$fa-var-firefox: "";
$fa-var-first-order: "";
$fa-var-flag: "";
$fa-var-flag-checkered: "";
$fa-var-flag-o: "";
$fa-var-flash: "";
$fa-var-flask: "";
$fa-var-flickr: "";
$fa-var-floppy-o: "";
$fa-var-folder: "";
$fa-var-folder-o: "";
$fa-var-folder-open: "";
$fa-var-folder-open-o: "";
$fa-var-font: "";
$fa-var-font-awesome: "";
$fa-var-fonticons: "";
$fa-var-fort-awesome: "";
$fa-var-forumbee: "";
$fa-var-forward: "";
$fa-var-foursquare: "";
$fa-var-free-code-camp: "";
$fa-var-frown-o: "";
$fa-var-futbol-o: "";
$fa-var-gamepad: "";
$fa-var-gavel: "";
$fa-var-gbp: "";
$fa-var-ge: "";
$fa-var-gear: "";
$fa-var-gears: "";
$fa-var-genderless: "";
$fa-var-get-pocket: "";
$fa-var-gg: "";
$fa-var-gg-circle: "";
$fa-var-gift: "";
$fa-var-git: "";
$fa-var-git-square: "";
$fa-var-github: "";
$fa-var-github-alt: "";
$fa-var-github-square: "";
$fa-var-gitlab: "";
$fa-var-gittip: "";
$fa-var-glass: "";
$fa-var-glide: "";
$fa-var-glide-g: "";
$fa-var-globe: "";
$fa-var-google: "";
$fa-var-google-plus: "";
$fa-var-google-plus-circle: "";
$fa-var-google-plus-official: "";
$fa-var-google-plus-square: "";
$fa-var-google-wallet: "";
$fa-var-graduation-cap: "";
$fa-var-gratipay: "";
$fa-var-grav: "";
$fa-var-group: "";
$fa-var-h-square: "";
$fa-var-hacker-news: "";
$fa-var-hand-grab-o: "";
$fa-var-hand-lizard-o: "";
$fa-var-hand-o-down: "";
$fa-var-hand-o-left: "";
$fa-var-hand-o-right: "";
$fa-var-hand-o-up: "";
$fa-var-hand-paper-o: "";
$fa-var-hand-peace-o: "";
$fa-var-hand-pointer-o: "";
$fa-var-hand-rock-o: "";
$fa-var-hand-scissors-o: "";
$fa-var-hand-spock-o: "";
$fa-var-hand-stop-o: "";
$fa-var-handshake-o: "";
$fa-var-hard-of-hearing: "";
$fa-var-hashtag: "";
$fa-var-hdd-o: "";
$fa-var-header: "";
$fa-var-headphones: "";
$fa-var-heart: "";
$fa-var-heart-o: "";
$fa-var-heartbeat: "";
$fa-var-history: "";
$fa-var-home: "";
$fa-var-hospital-o: "";
$fa-var-hotel: "";
$fa-var-hourglass: "";
$fa-var-hourglass-1: "";
$fa-var-hourglass-2: "";
$fa-var-hourglass-3: "";
$fa-var-hourglass-end: "";
$fa-var-hourglass-half: "";
$fa-var-hourglass-o: "";
$fa-var-hourglass-start: "";
$fa-var-houzz: "";
$fa-var-html5: "";
$fa-var-i-cursor: "";
$fa-var-id-badge: "";
$fa-var-id-card: "";
$fa-var-id-card-o: "";
$fa-var-ils: "";
$fa-var-image: "";
$fa-var-imdb: "";
$fa-var-inbox: "";
$fa-var-indent: "";
$fa-var-industry: "";
$fa-var-info: "";
$fa-var-info-circle: "";
$fa-var-inr: "";
$fa-var-instagram: "";
$fa-var-institution: "";
$fa-var-internet-explorer: "";
$fa-var-intersex: "";
$fa-var-ioxhost: "";
$fa-var-italic: "";
$fa-var-joomla: "";
$fa-var-jpy: "";
$fa-var-jsfiddle: "";
$fa-var-key: "";
$fa-var-keyboard-o: "";
$fa-var-krw: "";
$fa-var-language: "";
$fa-var-laptop: "";
$fa-var-lastfm: "";
$fa-var-lastfm-square: "";
$fa-var-leaf: "";
$fa-var-leanpub: "";
$fa-var-legal: "";
$fa-var-lemon-o: "";
$fa-var-level-down: "";
$fa-var-level-up: "";
$fa-var-life-bouy: "";
$fa-var-life-buoy: "";
$fa-var-life-ring: "";
$fa-var-life-saver: "";
$fa-var-lightbulb-o: "";
$fa-var-line-chart: "";
$fa-var-link: "";
$fa-var-linkedin: "";
$fa-var-linkedin-square: "";
$fa-var-linode: "";
$fa-var-linux: "";
$fa-var-list: "";
$fa-var-list-alt: "";
$fa-var-list-ol: "";
$fa-var-list-ul: "";
$fa-var-location-arrow: "";
$fa-var-lock: "";
$fa-var-long-arrow-down: "";
$fa-var-long-arrow-left: "";
$fa-var-long-arrow-right: "";
$fa-var-long-arrow-up: "";
$fa-var-low-vision: "";
$fa-var-magic: "";
$fa-var-magnet: "";
$fa-var-mail-forward: "";
$fa-var-mail-reply: "";
$fa-var-mail-reply-all: "";
$fa-var-male: "";
$fa-var-map: "";
$fa-var-map-marker: "";
$fa-var-map-o: "";
$fa-var-map-pin: "";
$fa-var-map-signs: "";
$fa-var-mars: "";
$fa-var-mars-double: "";
$fa-var-mars-stroke: "";
$fa-var-mars-stroke-h: "";
$fa-var-mars-stroke-v: "";
$fa-var-maxcdn: "";
$fa-var-meanpath: "";
$fa-var-medium: "";
$fa-var-medkit: "";
$fa-var-meetup: "";
$fa-var-meh-o: "";
$fa-var-mercury: "";
$fa-var-microchip: "";
$fa-var-microphone: "";
$fa-var-microphone-slash: "";
$fa-var-minus: "";
$fa-var-minus-circle: "";
$fa-var-minus-square: "";
$fa-var-minus-square-o: "";
$fa-var-mixcloud: "";
$fa-var-mobile: "";
$fa-var-mobile-phone: "";
$fa-var-modx: "";
$fa-var-money: "";
$fa-var-moon-o: "";
$fa-var-mortar-board: "";
$fa-var-motorcycle: "";
$fa-var-mouse-pointer: "";
$fa-var-music: "";
$fa-var-navicon: "";
$fa-var-neuter: "";
$fa-var-newspaper-o: "";
$fa-var-object-group: "";
$fa-var-object-ungroup: "";
$fa-var-odnoklassniki: "";
$fa-var-odnoklassniki-square: "";
$fa-var-opencart: "";
$fa-var-openid: "";
$fa-var-opera: "";
$fa-var-optin-monster: "";
$fa-var-outdent: "";
$fa-var-pagelines: "";
$fa-var-paint-brush: "";
$fa-var-paper-plane: "";
$fa-var-paper-plane-o: "";
$fa-var-paperclip: "";
$fa-var-paragraph: "";
$fa-var-paste: "";
$fa-var-pause: "";
$fa-var-pause-circle: "";
$fa-var-pause-circle-o: "";
$fa-var-paw: "";
$fa-var-paypal: "";
$fa-var-pencil: "";
$fa-var-pencil-square: "";
$fa-var-pencil-square-o: "";
$fa-var-percent: "";
$fa-var-phone: "";
$fa-var-phone-square: "";
$fa-var-photo: "";
$fa-var-picture-o: "";
$fa-var-pie-chart: "";
$fa-var-pied-piper: "";
$fa-var-pied-piper-alt: "";
$fa-var-pied-piper-pp: "";
$fa-var-pinterest: "";
$fa-var-pinterest-p: "";
$fa-var-pinterest-square: "";
$fa-var-plane: "";
$fa-var-play: "";
$fa-var-play-circle: "";
$fa-var-play-circle-o: "";
$fa-var-plug: "";
$fa-var-plus: "";
$fa-var-plus-circle: "";
$fa-var-plus-square: "";
$fa-var-plus-square-o: "";
$fa-var-podcast: "";
$fa-var-power-off: "";
$fa-var-print: "";
$fa-var-product-hunt: "";
$fa-var-puzzle-piece: "";
$fa-var-qq: "";
$fa-var-qrcode: "";
$fa-var-question: "";
$fa-var-question-circle: "";
$fa-var-question-circle-o: "";
$fa-var-quora: "";
$fa-var-quote-left: "";
$fa-var-quote-right: "";
$fa-var-ra: "";
$fa-var-random: "";
$fa-var-ravelry: "";
$fa-var-rebel: "";
$fa-var-recycle: "";
$fa-var-reddit: "";
$fa-var-reddit-alien: "";
$fa-var-reddit-square: "";
$fa-var-refresh: "";
$fa-var-registered: "";
$fa-var-remove: "";
$fa-var-renren: "";
$fa-var-reorder: "";
$fa-var-repeat: "";
$fa-var-reply: "";
$fa-var-reply-all: "";
$fa-var-resistance: "";
$fa-var-retweet: "";
$fa-var-rmb: "";
$fa-var-road: "";
$fa-var-rocket: "";
$fa-var-rotate-left: "";
$fa-var-rotate-right: "";
$fa-var-rouble: "";
$fa-var-rss: "";
$fa-var-rss-square: "";
$fa-var-rub: "";
$fa-var-ruble: "";
$fa-var-rupee: "";
$fa-var-s15: "";
$fa-var-safari: "";
$fa-var-save: "";
$fa-var-scissors: "";
$fa-var-scribd: "";
$fa-var-search: "";
$fa-var-search-minus: "";
$fa-var-search-plus: "";
$fa-var-sellsy: "";
$fa-var-send: "";
$fa-var-send-o: "";
$fa-var-server: "";
$fa-var-share: "";
$fa-var-share-alt: "";
$fa-var-share-alt-square: "";
$fa-var-share-square: "";
$fa-var-share-square-o: "";
$fa-var-shekel: "";
$fa-var-sheqel: "";
$fa-var-shield: "";
$fa-var-ship: "";
$fa-var-shirtsinbulk: "";
$fa-var-shopping-bag: "";
$fa-var-shopping-basket: "";
$fa-var-shopping-cart: "";
$fa-var-shower: "";
$fa-var-sign-in: "";
$fa-var-sign-language: "";
$fa-var-sign-out: "";
$fa-var-signal: "";
$fa-var-signing: "";
$fa-var-simplybuilt: "";
$fa-var-sitemap: "";
$fa-var-skyatlas: "";
$fa-var-skype: "";
$fa-var-slack: "";
$fa-var-sliders: "";
$fa-var-slideshare: "";
$fa-var-smile-o: "";
$fa-var-snapchat: "";
$fa-var-snapchat-ghost: "";
$fa-var-snapchat-square: "";
$fa-var-snowflake-o: "";
$fa-var-soccer-ball-o: "";
$fa-var-sort: "";
$fa-var-sort-alpha-asc: "";
$fa-var-sort-alpha-desc: "";
$fa-var-sort-amount-asc: "";
$fa-var-sort-amount-desc: "";
$fa-var-sort-asc: "";
$fa-var-sort-desc: "";
$fa-var-sort-down: "";
$fa-var-sort-numeric-asc: "";
$fa-var-sort-numeric-desc: "";
$fa-var-sort-up: "";
$fa-var-soundcloud: "";
$fa-var-space-shuttle: "";
$fa-var-spinner: "";
$fa-var-spoon: "";
$fa-var-spotify: "";
$fa-var-square: "";
$fa-var-square-o: "";
$fa-var-stack-exchange: "";
$fa-var-stack-overflow: "";
$fa-var-star: "";
$fa-var-star-half: "";
$fa-var-star-half-empty: "";
$fa-var-star-half-full: "";
$fa-var-star-half-o: "";
$fa-var-star-o: "";
$fa-var-steam: "";
$fa-var-steam-square: "";
$fa-var-step-backward: "";
$fa-var-step-forward: "";
$fa-var-stethoscope: "";
$fa-var-sticky-note: "";
$fa-var-sticky-note-o: "";
$fa-var-stop: "";
$fa-var-stop-circle: "";
$fa-var-stop-circle-o: "";
$fa-var-street-view: "";
$fa-var-strikethrough: "";
$fa-var-stumbleupon: "";
$fa-var-stumbleupon-circle: "";
$fa-var-subscript: "";
$fa-var-subway: "";
$fa-var-suitcase: "";
$fa-var-sun-o: "";
$fa-var-superpowers: "";
$fa-var-superscript: "";
$fa-var-support: "";
$fa-var-table: "";
$fa-var-tablet: "";
$fa-var-tachometer: "";
$fa-var-tag: "";
$fa-var-tags: "";
$fa-var-tasks: "";
$fa-var-taxi: "";
$fa-var-telegram: "";
$fa-var-television: "";
$fa-var-tencent-weibo: "";
$fa-var-terminal: "";
$fa-var-text-height: "";
$fa-var-text-width: "";
$fa-var-th: "";
$fa-var-th-large: "";
$fa-var-th-list: "";
$fa-var-themeisle: "";
$fa-var-thermometer: "";
$fa-var-thermometer-0: "";
$fa-var-thermometer-1: "";
$fa-var-thermometer-2: "";
$fa-var-thermometer-3: "";
$fa-var-thermometer-4: "";
$fa-var-thermometer-empty: "";
$fa-var-thermometer-full: "";
$fa-var-thermometer-half: "";
$fa-var-thermometer-quarter: "";
$fa-var-thermometer-three-quarters: "";
$fa-var-thumb-tack: "";
$fa-var-thumbs-down: "";
$fa-var-thumbs-o-down: "";
$fa-var-thumbs-o-up: "";
$fa-var-thumbs-up: "";
$fa-var-ticket: "";
$fa-var-times: "";
$fa-var-times-circle: "";
$fa-var-times-circle-o: "";
$fa-var-times-rectangle: "";
$fa-var-times-rectangle-o: "";
$fa-var-tint: "";
$fa-var-toggle-down: "";
$fa-var-toggle-left: "";
$fa-var-toggle-off: "";
$fa-var-toggle-on: "";
$fa-var-toggle-right: "";
$fa-var-toggle-up: "";
$fa-var-trademark: "";
$fa-var-train: "";
$fa-var-transgender: "";
$fa-var-transgender-alt: "";
$fa-var-trash: "";
$fa-var-trash-o: "";
$fa-var-tree: "";
$fa-var-trello: "";
$fa-var-tripadvisor: "";
$fa-var-trophy: "";
$fa-var-truck: "";
$fa-var-try: "";
$fa-var-tty: "";
$fa-var-tumblr: "";
$fa-var-tumblr-square: "";
$fa-var-turkish-lira: "";
$fa-var-tv: "";
$fa-var-twitch: "";
$fa-var-twitter: "";
$fa-var-twitter-square: "";
$fa-var-umbrella: "";
$fa-var-underline: "";
$fa-var-undo: "";
$fa-var-universal-access: "";
$fa-var-university: "";
$fa-var-unlink: "";
$fa-var-unlock: "";
$fa-var-unlock-alt: "";
$fa-var-unsorted: "";
$fa-var-upload: "";
$fa-var-usb: "";
$fa-var-usd: "";
$fa-var-user: "";
$fa-var-user-circle: "";
$fa-var-user-circle-o: "";
$fa-var-user-md: "";
$fa-var-user-o: "";
$fa-var-user-plus: "";
$fa-var-user-secret: "";
$fa-var-user-times: "";
$fa-var-users: "";
$fa-var-vcard: "";
$fa-var-vcard-o: "";
$fa-var-venus: "";
$fa-var-venus-double: "";
$fa-var-venus-mars: "";
$fa-var-viacoin: "";
$fa-var-viadeo: "";
$fa-var-viadeo-square: "";
$fa-var-video-camera: "";
$fa-var-vimeo: "";
$fa-var-vimeo-square: "";
$fa-var-vine: "";
$fa-var-vk: "";
$fa-var-volume-control-phone: "";
$fa-var-volume-down: "";
$fa-var-volume-off: "";
$fa-var-volume-up: "";
$fa-var-warning: "";
$fa-var-wechat: "";
$fa-var-weibo: "";
$fa-var-weixin: "";
$fa-var-whatsapp: "";
$fa-var-wheelchair: "";
$fa-var-wheelchair-alt: "";
$fa-var-wifi: "";
$fa-var-wikipedia-w: "";
$fa-var-window-close: "";
$fa-var-window-close-o: "";
$fa-var-window-maximize: "";
$fa-var-window-minimize: "";
$fa-var-window-restore: "";
$fa-var-windows: "";
$fa-var-won: "";
$fa-var-wordpress: "";
$fa-var-wpbeginner: "";
$fa-var-wpexplorer: "";
$fa-var-wpforms: "";
$fa-var-wrench: "";
$fa-var-xing: "";
$fa-var-xing-square: "";
$fa-var-y-combinator: "";
$fa-var-y-combinator-square: "";
$fa-var-yahoo: "";
$fa-var-yc: "";
$fa-var-yc-square: "";
$fa-var-yelp: "";
$fa-var-yen: "";
$fa-var-yoast: "";
$fa-var-youtube: "";
$fa-var-youtube-play: "";
$fa-var-youtube-square: "";
