@import 'lib/font-gilroy';
// Mixins / Core
@import 'layouts/customize';
@import 'mixins/colors';
@import 'settings';
@import 'core/core';

// foundation
@import 'foundation-sites/foundation';
@include foundation-everything($flex: true);

@import 'mixins/type';
@import 'mixins/buttons';
@import 'mixins/components';
@import 'mixins/images';
@import 'mixins/animations';

// Vendor
// @import 'lib/bebas-neue';
@import 'lib/font-awesome/font-awesome';
@import 'lib/owl-carousel/owl.carousel';
@import 'lib/owl-carousel/owl.theme.default';
@import 'lib/list-group';
@import 'lib/weather-icons';
@import 'lib/animate';

// Custom Stylesheets
@import 'style';
@import 'layouts/header';
@import 'layouts/footer';
@import 'layouts/form';
@import 'layouts/workout-tiles';
@import 'layouts/workouts';
@import 'layouts/onboarding';
@import 'layouts/dashboard';
@import 'layouts/meals';
@import 'layouts/overlays';
@import 'layouts/planner';
@import 'layouts/select';
@import 'layouts/_design_guide';

// style update based on mobile design guide
@import 'layouts/_layout_update';
@import 'ember-power-select';
