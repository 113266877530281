header#header {
  position: relative;
  z-index: 998;
  border-bottom: 1px solid $border-color;
  .top-bar {
    height: $topbar-height;
    .logo-img-area {
      padding-left: 18px; } }
  .top-bar, .top-bar a {
    color: $black;
    font-weight: bold; }
  #week-meta {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    color: $gray-light; }
  #header-user-program {
    @include transform(translate(-50%, 0));
    position: absolute;
    top: rem-calc(15);
    left: 50%;
    width: 100%;
    text-align: center;
    pointer-events: none;
    color: $gray-light; }
  .weather-widget {
    text-transform: capitalize;
    font-weight: bold;
    color: $gray-light;
    .weathericon {
      @include filter(invert(30%));
      vertical-align: middle;
      margin: rem-calc(-10 -5 0 -10); } }
  nav ul a {
    display: block;
    padding: rem-calc(0);
    .nc-icon {
      vertical-align: middle;
      margin: rem-calc(-4 5 0 0); } }
  .notification-dot {
    position: absolute;
    top: -7px;
    right: 0px; } }

nav.action-bar {
  @extend .small-collapse;
  position: relative;
  z-index: 2;
  padding: rem-calc(10);
  border-bottom: 1px solid $border-color;
  text-align: center;
  color: $pink;
  > .text-right .button {
    float: right !important; }
  &.select-program-bar {
    position: fixed;
    top: 0;
    width: 100%; }
  .button {
    margin-bottom: 0;
    font-weight: bold;
    &.pink {
      border-color: transparent !important; }
    &.no-border {
      float: left;
      margin-left: rem-calc(-5);
      font-weight: normal; } }
  .title {
    h1, h2, h3, li.tabs-title a {
      line-height: 1;
      margin: rem-calc(14 0 0);
      font-size: rem-calc(24);
      color: $pink;
      font-weight: 600; }
    h1 {
      span {
        display: block;
        font-family: $body-font-family;
        font-size: rem-calc(14);
        font-weight: normal;
        text-transform: uppercase; } } }
  .center-title {
    @include flex;
    font-size: 22px;
    color: $black;
    font-weight: 600; }
  &:not(:first-child) {
    .notification-bar {
      border-top: 1px solid $white; } }
  .completion-bar, .notification-bar {
    @extend .small-order-5;
    @extend .small-12;
    @extend .columns;
    max-width: none;
    flex: initial;
    width: 110%;
    margin: rem-calc(10 -10 -10);
    text-align: center;
    padding: 1rem;
    font-size: rem-calc(14);
    color: white;
    border-top: 1px solid $border-color;
    a {
      display: inline-block;
      text-decoration: underline;
      color: white;
      &:hover {
        @include transform(translate(0, -2px));
        color: $secondary-color; } } }
  .completion-bar {
    &.resistance {
      background: darken($pink, 12%); }
    &.cardio {
      background: darken($cardio, 12%); }
    &.recovery {
      background: darken($recovery, 12%); }
    &.challenge {
      background: darken($orange, 12%); } }
  .notification-bar {
    margin: 0;
    text-align: left;
    padding: 1rem 2rem;
    background: $pink-light;
    color: $black;
    position: relative;
    .media {
      margin-top: 0;
      .media-left {
        line-height: 1;
        // exclamation point in the icon
        .subscription-expiring-inner, .trial-remaining-inner {
          position: absolute;
          left: 42%;
          top: 28%;
          color: $white;
          font-size: 30px; }
        .trial-remaining-inner {
          left: 36%;
          top: 32%;
          font-size: 24px; }
        .billing-issue-inner, .gift-friend-inner {
          height: 16px;
          width: 16px;
          padding-top: 1px;
          border-radius: 50%;
          font-size: 14px;
          color: $white;
          line-height: 1;
          text-align: center;
          background: darken($pink, 10%);
          position: absolute;
          bottom: 5%;
          right: -5%; } } }
    a {
      color: $gray;
      text-decoration: unset;
      &:hover {
        color: darken($gray, 10%);
        @include transform(none); } }
    .notification-dismiss {
      position: absolute;
      top: 15px;
      right: 15px; }
    /* in-app messaging */
    .in-app-msg-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
    .in-app-msg-content {
      flex: 1 0 800px;
      max-width: 800px;
      margin: 0 2rem 1rem 0;
      .sweat-logo-circle {
        text-align: center;
        height: 48px;
        width: 48px;
        padding-top: 6px;
        border-radius: 1000px;
        background: $pink;
        border: 2px solid $sweat-logo-circle-border; }
      p:last-child {
        margin-bottom: 0; }
      &:last-child {
        margin-bottom: 0; } }
    .in-app-msg-pane {
      flex: 1 0 535px;
      position: relative;
      display: flex;
      background-color: $white;
      max-width: 535px;
      width: 100%;
      height: 80px;
      align-items: center;
      margin-bottom: 0;
      border-radius: $global-radius;
      @include box-shadow(0 3px 7px -2px rgba(0, 0, 0, 0.1));
      @include transition(all 0.35s ease);
      &:hover {
        @include transform(scale(1.01));
        @include box-shadow(0 4px 15px -2px rgba(0, 0, 0, 0.12)); }
      .pane-header {
        flex: 1;
        height: 100%;
        max-width: 110px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        .trainer-img {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top; } }
      .pane-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 20px;
        .title {
          color: $black;
          margin: 0; }
        .sub-title {
          color: $gray-light;
          font-size: 14px;
          margin-bottom: 0; } } } }

  ul.tabs {
    margin-bottom: -5px;
    border: none;
    li.tabs-title {
      @include opacity(0.5);
      @extend .font-montserrat;
      float: none;
      display: inline-block;
      margin: rem-calc(10);
      &.is-active {
        @include opacity(1); }
      a {
        margin: 0;
        padding: rem-calc(0 5);
        font-size: rem-calc(24);
        .nc-icon {
          margin-right: rem-calc(5); } } } } }

@media #{$small-only}, #{$medium-only} {
  header#header .top-bar {
    height: auto;
    padding: rem-calc(6 10);
    nav ul li a {
      padding-top: 2px;
      min-width: 0;
      border: none; }
    #week-meta, #header-user-program {
      top: rem-calc(14);
      margin-top: rem-calc(-2);
      font-size: rem-calc(16); } }

  nav.action-bar {
    padding: rem-calc(5);
    ul.tabs {
      margin-bottom: 0; }
    .notification-bar {
      .in-app-msg-wrapper .in-app-msg-content, .in-app-msg-pane {
        flex-basis: 100% !important; } } }


  nav#mobile-bottom-nav {
    position: fixed;
    z-index: 999;
    background {}
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
    background: $topbar-background;
    border-top: 1px solid $gray-light;
    a {
      display: block;
      padding: rem-calc(3 10);
      color: $black;
      font-weight: bold;
      font-size: rem-calc(9);
      .nc-icon {
        display: block;
        vertical-align: middle;
        margin: 5px auto 0; } } } }

@media #{$small-only} {
  header#header {
    .top-bar {
      .logo-img-area {
        padding-left: 10px; } } }

  nav.action-bar {
    .center-title {
      font-size: 20px; } } }



