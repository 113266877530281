#shopping-list {
  .preference-setting {
    position: absolute;
    top: 5px;
    right: 0; } }
#meal-show {
  background: $secondary-color;
  text-align: center;
  .hero {
    position: relative;
    overflow: hidden;
    height: 60vh;
    max-height: 500px;
    > .row {
      @include middle;
      z-index: 3;
      > .columns {
        padding: 0 2rem; } }
    nav a {
      color: $gray; }
    h1 {
      color: $pink;
      font-size: 4vh;
      margin: 0; }
    &:after, .bg {
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px; }
    &:after {
      display: block;
      content: ' ';
      z-index: 2;
      background: rgba(255,255,255,0.7); }
    .bg {
      @include filter(blur(5px));
      z-index: 1;
      background-size: cover;
      background-position: center center; } }
  .meta {
    background: $black;
    p {
      color: white;
      margin: 1rem 0;
      text-transform: uppercase; } }
  article {
    .orbit-previous, .orbit-next {
      background: none;
      color: $gray;
      margin: 0 -1rem;
      font-size: 20px;
      cursor: pointer; } } }
