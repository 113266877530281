footer#footer {
  padding-top: 3rem;
  font-size: rem-calc(14);
  color: $gray-light;
  .brand {
    img {
      max-height: 60px;
      margin-bottom: 1rem; }
    span {
      color: #a7a7a7;
      font-weight: 400; } }
  h2, h3 {
    font-family: $body-font-family;
    text-transform: uppercase;
    font-size: rem-calc(14);
    margin-bottom: 0.5em; }
  ul.list-unstyled li {
    margin-bottom: 2px;
    text-transform: capitalize; }
  a {
    color: $gray-light;
    &:hover {
      color: $pink; } }
  .social-links {
    text-align: left;
    a {
      font-size: 2rem;
      margin: 0 0.5rem; } }

  .footer-mobile {
    padding: 0 1rem;
    .footer-menus {
      margin-bottom: 0;
      a {
        display: block;
        text-align: left;
        border-bottom: 1px solid $border-color;
        font-size: rem-calc(16);
        padding: 10px 0;
        i {
          float: right;
          margin-top: 3px; } } }
    .social-links {
      text-align: center;
      padding: 1rem; } }

  .copyright {
    background: $gray-light-bg;
    color: $gray-light;
    text-align: center;
    padding: 1rem;
    margin-top: 2rem; } }

footer.footer-nav {
  @include box-shadow(0 -10px 15px rgba(0,0,0,0.2));
  position: fixed;
  z-index: 9999;
  background: black;
  bottom: 0;
  left: 0;
  right: 0;
  .button {
    @extend .font-montserrat;
    display: block;
    margin: 0;
    border-radius: 0;
    font-size: 17px !important;
    padding: 1rem;
    font-weight: 800; } }

@media #{$small-only} {
  footer#footer {
    padding-top: 2rem; } }
