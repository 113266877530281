// dashboard & onboarding header
#today-dashboard, .onboarding {
  .page-title {
    .bottom-links a *:hover {
      color: darken($gray, 10%); }
    .side-button {
      font-weight: bold;
      margin: 0 0 5px 20px;
      padding: 4px 6px; }
    &.dashboard-header {
      .media .media-body {
        > h1, h5 {
          margin-bottom: 0; }
        > h1 {
          font-size: 34px; }
        > h5 {
          font-size: 11px;
          font-weight: 600;
          color: $pink;
          text-transform: uppercase;
          letter-spacing: 0.5px; }
        .bottom-links {
          > h5 {
            display: inline-block;
            color: $gray-50;
            font-size: 15px;
            font-weight: 500; } } } } } }

#today-dashboard {
  overflow: hidden;
  &.main-dashboard {
    hr {
      margin: 0; }
    section {
      padding: 2rem; }
    /* community events pulic class*/
    .community-event-button {
      background-color: unset;
      @include background(100%);
      background: $pink;
      border: none;
      &:hover {
        @include box-shadow(1px 1px 10px 3px rgba(253,153,193,0.44)); } }
    .event-time-info {
      @include flex(center, flex-start);
      font-size: 14px;
      font-weight: bold; }
    .progress-info {
      width: 100%;
      .progress-info-title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px; }
      .progress {
        margin: 0;
        height: 10px;
        border-radius: 50px;
        background-color: $gray-lighter;
        .progress-meter {
          border-radius: 50px;
          @include background(100%, left);
          background: linear-gradient(45deg, #ff8e8e 0%, #fc94c2 100%);
          background-color: unset; }
        &:hover, &:focus {
          outline: none; } } }
    .community-member-pane {
      @include flex(center, flex-start, row, wrap);
      width: 100%;
      margin-top: 2rem;
      .overlapping-avatars {
        list-style-type: none;
        @include flex(center, flex-start);
        margin: 0 1rem 0 0;
        padding: 0;
        @media #{$small-only} {
          margin-bottom: 10px; }
        li {
          position: relative;
          @include flex;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%; }
          &.total-number {
            background: white;
            color: $pink;
            font-weight: bold;
            width: fit-content;
            min-width: 50px;
            padding: 10px;
            border-radius: 1000px; }
          &:nth-child(n+2) {
            margin-left: -20px; } } }
      .member-info {
        font-size: 14px;
        font-weight: 600;
        color: $gray; } }
    /* community events layout*/
    #community-events {
      @include flex(flex-start, flex-start, column);
      max-width: 800px;
      width: 100%;
      &.collapsed {
        .community-events-pane {
          margin-bottom: 0;
          .pane-header .title {
            margin-bottom: 0; } } }
      .community-events-pane {
        position: relative;
        display: flex;
        align-items: stretch;
        min-height: 200px;
        border-radius: $rebrand-global-radius;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: 120%;
        background-position: right 26%;
        // background: radial-gradient(at 50% -40%, #ff9ac7 15%, #f8006f 70%)
        width: 100%;
        > .row {
          flex: 1;
          // background: linear-gradient(0deg, rgba(0,0,0,0.1), transparent)
          background: rgba(0,0,0,0.2); }
        .pane-left {
          display: flex;
          align-items: stretch;
          .pane-header {
            position: relative;
            flex: 1;
            display: flex;
            align-items: stretch;
            .title {
              @include flex(center, space-between);
              margin-bottom: 10px;
              .sweat-nation-logo {
                height: 25px;
                width: auto; }
              svg {
                height: 25px;
                width: auto; } }
            .challenge-trainers {
              flex: 1;
              @include background(cover); } } }
        .pane-right {
          padding-top: 16px;
          padding-bottom: 16px;
          .pane-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.25rem;
            .community-events-details {
              color: $white;
              text-align: center;
              font-size: 18px;
              line-height: 1;
              font-weight: bold;
              margin-bottom: 10px; }
            .button {
              @include flex;
              padding: 0 16px;
              min-height: 40px;
              min-width: 200px;
              margin: 0; }
            .event-time-info {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              font-weight: 600; } } } } }
    /* community events modal box layout*/
    .workout-info {
      @include flex; }
    .community-events-modal {
      transform: none;
      top: unset;
      left: unset;
      display: flex;
      position: relative;
      flex-direction: column;
      padding: 0;
      max-width: 800px;
      color: $black;
      &.details .modal-content {
        text-align: center; }
      &.programs .modal-content {
        .rebrand-text-title {
          padding-top: 40px;
          margin-bottom: 8px; }
        .rebrand-text-content {
          margin-bottom: 2rem; } }
      &.confirm .modal-content {
        text-align: center; }
      a.modal-dismiss {
        top: 30px;
        left: 30px;
        right: unset; }
      .modal-dismiss-bar {
        border: none; }
      .top-right {
        position: absolute;
        top: 30px;
        right: 30px;
        width: auto;
        z-index: 10;
        a.dropdown-expand {
          color: $white;
          padding: 0;
          font-size: 24px;
          &:after {
            display: none; } }
        .is-dropdown-submenu {
          @include box-shadow(0 3px 8px rgba(0,0,0,.3));
          border-radius: $global-radius;
          min-width: 170px;
          > li {
            a {
              font-size: 14px;
              font-weight: bold; }
            &:not(:last-child) {
              border-bottom: 1px solid $border-color;
              a {
                color: $gray;
                &:hover {
                  color: darken($gray,10%); } } } } } }
      .winback-modal-header {
        @include flex(flex-start, flex-start, column);
        position: relative;
        @include background(100%);
        background: linear-gradient(45deg, #ff8e8e 0%, #fc94c2 100%);
        z-index: 1;
        width: 100%;
        &:before {
          content: '';
          z-index: 0;
          position: absolute;
          @include background(100%, top);
          background-image: url("img/community-events-bubble3.png");
          width: 220px;
          height: 110px;
          top: 0;
          right: 20px; }
        .title {
          margin-bottom: 10px;
          .sweat-nation-logo {
            @include filter(brightness(0) invert(1));
            height: 25px;
            width: auto; } }
        .event-time-info {
          color: $white;
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 10px; }
        .community-member-pane {
          margin-bottom: 10px; }
        .winback-ad-text {
          p {
            color: $white;
            font-size: 14px;
            line-height: 1.5;
            margin-bottom: 4px;
            &:last-child {
              margin: 0; } } } }
      .modal-header {
        height: 310px;
        // background: radial-gradient(at 50% -40%, #ff9ac7 15%, #f8006f 70%)
        background-image: url("img/challenge-liquid-layer.png");
        @include background(cover);
        width: 100%;
        border-radius: $global-radius $global-radius 0 0;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: stretch;
        .modal-header-layer {
          flex: 1;
          // background-image: url(/assets/img/challenge-trainers.png)
          @include background(cover, center 15%); }
        .event-image {
          position: absolute;
          height: auto;
          width: 250px;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          z-index: 2; } }
      .modal-content {
        padding: 2rem;
        width: 100%;
        flex: auto;
        position: relative;
        z-index: 1;
        max-height: 60vh;
        overflow: scroll;
        .rebrand-text-title {
          margin-bottom: 0; }
        .challenge-content-section {
          margin-bottom: 2rem;
          > p:last-child {
            margin-bottom: 0; } }
        &.no-modal-header {
          max-height: 80vh; }
        .owl-carousel .workout-tile.card {
          width: 250px;
          .card-section {
            height: 150px; }
          .card-divider {
            h3 {
              color: $black;
              margin-bottom: 10px; } } }
        .event-details {
          font-size: 14px;
          color: $gray;
          text-align: center;
          p, strong {
            font-size: 14px;
            color: $gray;
            text-align: center;
            margin-bottom: 4px; } }
        .event-time-info {
          @include flex;
          margin-bottom: 20px;
          font-size: 14px;
          .number-pane {
            background-color: $pink;
            color: $white; } }
        .community-member-pane {
          @include flex(center, center, column);
          .overlapping-avatars {
            margin: 0 0 10px 0;
            li.total-number {
              color: $gray;
              font-weight: normal; } }
          .member-info {
            font-weight: normal;
            text-align: center; } }
        .progress-info {
          margin-bottom: 20px;
          @include flex(center, center, column);
          width: 100%;
          .progress-info-title {
            font-size: 14px;
            margin-bottom: 10px; }
          .progress {
            width: 100%;
            max-width: 600px; } }
        .action-area {
          @include flex(center, center, column);
          .button {
            width: 100%;
            max-width: unset;
            margin: 0 0 10px 0;
            &:last-of-type {
              margin: 0; }
            &.join-now-button, &.loading-button {
              background-color: unset;
              @include background(100%);
              background: $pink;
              border: none; } }
          .terms-link {
            @include flex;
            padding: 10px 0 0 0;
            font-size: 12px; } }
        .winback-content {
          max-width: 100%;
          margin: 0;
          .plan-selection-row {
            width: 100%;
            @include flex(center, space-between);
            margin-bottom: 20px;
            > a.selection-button {
              @include flex(flex-start, center, column);
              height: fit-content;
              max-width: 49%;
              margin: 0;
              min-height: 160px;
              > p {
                font-family: 'Montserrat', sans-serif;
                text-align: left;
                width: 100%;
                color: $gray;
                font-weight: 500;
                margin-bottom: 0;
                &.main-text {
                  font-size: 32px;
                  font-weight: bold;
                  color: $black; } } } } } }

      &.invite-friend {
        max-width: 600px;
        a.modal-dismiss {
          color: $pink; }
        .modal-header {
          &:before {
            background: none; }
          .sweat-nation-image {
            width: 150px; }
          .sweat-nation-logo {
            height: 20px; } }
        .modal-content {
          padding-top: 20px !important;
          .community-member-pane {
            margin-bottom: 100px !important; } } }
      &.event-completed {
        @include background(100%);
        background: linear-gradient(45deg, #ff8e8e 0%, #fc94c2 100%) !important;
        border: none;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 40px;
          height: 110px;
          width: 250px;
          @include background(100%, top);
          background-image: url("img/community-events-bubble3.png"); }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 40px;
          height: 110px;
          width: 250px;
          @include background(100%, bottom);
          background-image: url("img/community-events-bubble1.png"); }
        .modal-header {
          &:before {
            background: none; } }
        .modal-content {
          .event-details {
            color: $white;
            p {
              color: $white; } } } } }

    // my program carousels section
    #my-program, #dashboard-otherworkouts {
      padding-top: 0; } }

  [class*='sub-category-'] {
    margin: 1rem; }
  [class*='sub-others-'] {
    margin: 1rem 0; }
  #food-today {
    position: relative;
    #week-range {
      position: absolute;
      z-index: 2;
      top: 9rem;
      left: 0;
      right: 0; }
    #owl-food-carousel {
      padding-top: 3.5rem;
      .owl-dot {
        position: relative;
        text-align: center;
        margin: 0.2rem;
        color: $gray-light;
        font-weight: bold;
        span {
          display: none; } }
      > .owl-stage-outer {
        padding: $column-gutter*5.5 0 $column-gutter*2;
        background: $secondary-color;
        margin-top: -4px; }
      // Week Nav
      > .owl-dots {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        .owl-dot {
          margin: 0.5rem 1rem;
          padding: 0.5rem;
          border-bottom: 4px solid transparent;
          &.active {
            color: $black;
            border-color: $pink; }
          &:before {
            display: block; } } }
      // Day Nav
      .owl-carousel .owl-dots {
        position: absolute;
        top: -2.5rem;
        left: 0;
        right: 0;
        margin: 0;
        .owl-dot {
          width: 24px;
          height: 24px;
          background: white;
          border: 1px solid $border-color;
          border-radius: 10000px;
          &.active {
            color: white;
            background: $pink;
            border-color: $pink; }
          &:before {
            display: block;
            font-size: 14px;
            margin-top: 1px; } } }
      article {
        padding: rem-calc(10 10 0);
        a {
          @extend .transit;
          display: block;
          &:hover {
            @include transform(scale(1.03) translate(0, -2px));
            img {
              @include box-shadow(0px 3px 10px rgba(0,0,0,0.2)); } }
          img {
            @extend .transit;
            margin: 1rem auto;
            width: 100%;
            height: auto; }
          h5, h6, p {
            @extend .ellipsis;
            margin-bottom: 0; }
          p {
            color: $gray; } } } } } }

.alert-screen > .row {
  height: 100vh;
  margin-top: -71px; }

ul.trainer-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li.trainer-item {
    display: inline-block;
    text-align: center;
    margin-right: 25px;
    &:hover {
      .trainer-img {
        transform: scale(1.04); } }
    .trainer-img {
      height: 100px;
      width: 100px;
      display: inline-block;
      transition: transform .2s; }
    .trainer-name {
      color: $black;
      line-height: 1.3;
      font-weight: bold;
      max-width: 80px;
      text-align: center;
      margin: 0 auto 20px; } } }
#panel-my-program {
  ul.trainer-list .trainer-name {
    margin-bottom: 0; } }

.referrals-no {
  height: 16px;
  width: 16px;
  border-radius: 10000px;
  background-color: $pink;
  display: inline-block;
  text-align: center;
  color: white;
  font-size: 12px;
  vertical-align: top;
  margin-left: -3px; }
#welcome-screen {
  .goal-item {
    padding: 16px 0;
    margin-top: 0;
    p.goal-detail {
      color: $gray-light;
      margin-bottom: 0;
      line-height: 1.3;
      font-size: 12px; }
    &:not(:last-child) {
      border-bottom: 1px solid $border-color; } }
  #welcome-modal-btn {
    margin: 0 auto; } }

ul.stacked-links {
  @include box-shadow(0 3px 20px -2px rgba(0, 0, 0, 0.1));
  margin-bottom: 40px;
  overflow: hidden;
  > li.list-group-item {
    padding: 0;
    .workout-name {
      text-align: left; }
    > a {
      padding: 15px;
      display: block;
      > .media {
        margin-top: 0;
        .media-body {
          p {
            font-weight: 600;
            text-align: left; } }
        .media-right {
          line-height: 1; } } } }
  &.blue {
    > li.list-group-item {
      .workout-name {
        color: $blue; }
      &:hover {
        .workout-name {
          color: darken($blue, 10%); } } } }
  &.orange {
    > li.list-group-item {
      .workout-name {
        color: $orange; }
      &:hover {
        .workout-name {
          color: darken($orange, 10%); } } } }
  &.gray {
    > li.list-group-item {
      .workout-name {
        color: $gray; }
      &:hover {
        .workout-name {
          color: darken($gray, 10%); } } } }
  &.black {
    > li.list-group-item {
      .workout-name {
        color: $black; }
      &:hover {
        .workout-name {
          color: darken($gray, 10%); } } } }
  &.cyber-week-pink {
    display: flex;
    flex-direction: column;
    box-shadow: none;
    overflow: visible;
    padding: 2px;
    > li.list-group-item {
      border: none;
      margin-bottom: 20px;
      > a {
        position: relative;
        border-radius: $global-radius;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
        .pane-label-tag {
          background-color: $black;
          color: $white;
          font-size: 12px; }
        .workout-name-subtitle {
          color: $black; }
        svg * {
          fill: $pink; }
        &.is-active {
          background-color: $pink;
          color: $white;
          .workout-name {
            color: $white; }
          .workout-name-subtitle {
            color: $white; }
          svg * {
            fill: $white; } } } } } }

body.ember-application {
  /* topbar notice carousel */
  .owl-carousel.topbar-notice-carousel {
    .owl-stage-outer .owl-stage {
      display: flex;
      align-items: stretch; }
    .topbar-notice-item-wrapper {
      min-width: 700px;
      max-width: 700px;
      padding: 0 0 0 2rem;
      #community-events {
        padding: 0; }
      .notification-pane {
        display: flex;
        position: relative;
        min-height: 200px;
        width: 100%;
        padding: 16px 40px 16px 20px;
        border-radius: $rebrand-global-radius;
        background-color: $simple-card-bg;
        a.close-button {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 8px;
          right: 12px;
          font-size: 18px;
          color: $gray-60;
          padding: 4px;
          &:hover {
            @include hover_action; } }
        .notification-main {
          height: 100%;
          max-height: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          .notification-title {
            // color: $white
            color: $gray-60;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 4px; }
          .notification-subtitle {
            // color: $purple-0
            color: $gray-60;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 4px;
            display: -webkit-box;
            display: box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            &:only-child {
              color: $white;
              margin-top: -1px;
              -webkit-line-clamp: 7; } }
          > .media {
            margin: 0;
            .media-left {
              padding-right: 1rem;
              .info-icon {
                font-size: 28px;
                color: $white;
                @include flex(flex-start);
                svg {
                  height: 27px;
                  width: auto;
                  margin-top: 6px; }
                i {
                  margin-top: 8px;
                  &.fa-trophy {
                    margin-top: 6px; } } } }
            .media-body {
 } }              // padding-top: 11px
          .notification-action {
            @include flex(center, flex-start);
            margin-top: auto;
            margin-bottom: 0;
            .button {
              margin: 0 0 0 auto;
              padding: 0 16px;
              min-height: 40px;
              min-width: 200px;
              @include flex; } } }
        .notification-dismiss {
          position: absolute;
          top: 4px;
          right: 8px;
          padding: 4px;
          font-size: 16px;
          color: $purple-0; }
        &.in-app-msg-pane {
          padding: 0;
          overflow: hidden;
          display: flex;
          align-items: stretch;
          position: relative;
          .pane-label-tag {
            top: 15px;
            left: 15px;
            right: unset;
            bottom: unset;
            transform: none; }
          .notification-title {
            color: $gray-60;
            font-size: 20px;
            font-weight: 700; }
          .notification-subtitle {
            color: $gray-60;
            font-size: 14px;
            margin-bottom: 10px;
            -webkit-line-clamp: 3; }
          .pane-left {
            min-width: 200px;
            @include background;
            .in-app-msg-img {
              height: 100%;
              width: 100%;
              @include background; } }
          .pane-right {
            flex: 1;
            padding: 16px 40px 16px 20px;
            display: flex;
            flex-direction: column;
            background-color: $simple-card-bg; } } }
      .community-events-pane {
        position: relative; } } }

  /* bottom snackbar */
  .bottom-snackbar-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    bottom: 0;
    left: 0;
    .snackbar-item {
      padding: 16px 40px 16px 16px;
      background-color: $gray-50;
      border-bottom: 1px solid $gray-10;
      h4 {
        margin: 0;
        color: $white; }
      p {
        margin: 0 0 4px 0;
        color: $white;
        &:last-child {
          margin-bottom: 0; } }
      &:last-child {
        border-bottom: none; }
      .snackbar-item-heading {}
      .snackbar-item-body {} } } }


@media #{$small-only} {
  #today-dashboard {
    &.main-dashboard section {
      padding: 20px; }
    .action-area {
      margin-bottom: 30px; }
    #owl-food-carousel article a img {
      margin: 0.5rem auto !important; }
    #community-events {
      .community-member-pane {
        margin-top: 5px; } }
    .community-events-modal {
      max-height: 80vh;
      overflow: scroll;
      .modal-header {
        height: 240px !important;
        &:before {
          height: 300px !important;
          width: 150% !important; }
        &:after {
          height: 160px !important;
          width: 200px !important; } }
      .modal-content {
        max-height: 40vh !important;
        &.no-modal-header {
          max-height: unset !important; } }
      &.winback-modal {
        align-self: flex-start !important;
        .plan-selection-row {
          flex-direction: column !important;
          > a.selection-button {
            max-width: 100% !important;
            width: 100% !important;
            min-height: fit-content !important;
            &:first-of-type {
              margin-bottom: 20px !important; } } } } } }

  body.ember-application {
    /* topbar notice carousel */
    .owl-carousel.topbar-notice-carousel {
      .topbar-notice-item-wrapper {
        min-width: 100vw;
        max-width: 100vw;
        padding: 0 20px 0 20px;
        position: relative;
        .notification-pane {
          &.in-app-msg-pane {
            @include background;
            .pane-left {
              display: none; }
            .pane-right {
              background: rgba(0,0,0,0.3);
              .notification-main {
                .notification-title {
                  display: flex;
                  align-items: center;
                  color: $white;
                  .pane-label-tag {
                    display: inline-block;
                    margin: 0 8px 0 0; } }
                .notification-subtitle {
                  color: $white; }
                .notification-action {
                  justify-content: center;
                  .button {
                    @include flex;
                    padding: 0 20px;
                    min-width: 200px;
                    min-height: 46px; } } } } } } } }

    #today-dashboard.main-dashboard #community-events {
      .community-events-pane {
        @include background(cover);
        margin-bottom: 20px;
        > .row {
          background: rgba(0,0,0,0.4); }
        .pane-right {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          padding: 0;
          .pane-content {
            padding: $column-gutter/2;
            gap: 0.5rem;
            .event-time-info {
              order: 3;
              font-size: 10px; }
            .community-events-details {
              margin: 0;
              order: 1;
              font-size: 18px; }
            .button {
              order: 2; } } } } } } }
