.button {
  @include transition(all 0.2s ease);
  background-color: $pink;
  border: 1px solid $pink;
  color: $white;
  font-family: $header-font-family;
  vertical-align: middle;
  font-weight: bold;
  text-transform: none;
  font-size: 15px !important;
  font-weight: 600;
  &:hover {
    @include transform(translate(0, -2px));
    @include box-shadow(0px 3px 10px rgba(0,0,0,0.2));
    &.no-hover, &.disabled, &[disabled] {
      @include transform(none);
      @include box-shadow(none); } }
  &.small {
    padding: 10px 16px; }
  &.hollow {
    background-color: transparent !important;
    color: $pink;
    border: 2px solid $pink;
    padding: 16px 20px; }
  &.no-border {
    border-color: transparent !important; }
  &.referrals-button {
    position: relative;
    background-color: $pink;
    border: none;
    padding: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 540px;
    margin: 0;
    .button-left .img-area svg {
      height: 45px;
      width: auto;
      padding-right: 20px; }
    .button-right {
      flex: 1;
      text-align: left;
      margin-top: -2px;
      p {
        margin: 0;
        font-weight: 600;
        font-size: 17px;
        color: $white; }
      small {
        margin: 0;
        font-size: 13px;
        color: $white; } }
    .right-icon {
      position: absolute;
      @include transform(translateY(-40%));
      top: 50%;
      right: 6px; } }
  &.circle {
    width: rem-calc(100);
    height: rem-calc(100);
    border-radius: 100%;
    &.large {
      border-width: 4px; } }
  .fa {
    &.fa-left {
      margin-right: rem-calc(7); }
    &.fa-right {
      margin-left: rem-calc(7); } }
  &.price-block, &.price-block-no-discount {
    position: relative;
    padding: 20px;
    width: 100%;
    height: 100%;
    h1 {
      font-size: 46px; }
    &.active {
      color: $pink !important;
      border: 1px $pink solid !important; } }
  &.price-block::before {
    padding: 5px;
    position: absolute;
    top: -11px;
    left: 10%;
    // height: 22px
    width: 80%;
    // font-weight: 300
    border-radius: 20px;
    background: $pink;
    color: $white;
    content: attr(title);
    font-size: 12px;
    line-height: 1;
    opacity: 0;
    transform: scale(0, 0);
    transition: all .2s linear; }
  &.price-block.active::before {
    transform: scale(1, 1);
    opacity: 1; }
  .btn-icon {
    position: absolute;
    left: 15px;
    top: 12px; } }

.icon-text-button {
  min-height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 20px;
  border-radius: $rebrand-global-radius;
  width: 100%;
  background-color: $default-bg-gray;
  color: $oil;
  font-size: 16px;
  @include transition(all 0.2s ease);
  .icon-area {
    font-size: 26px;
    margin: 4px 12px 0 0; }
  &:after {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 22px;
    color: $gray-50;
    font-weight: 300;
    font-family: "Font Awesome 5 Pro"; }
  &:hover {
    @include transform(translate(0, -2px));
    color: $oil; }
  &.icon-next {
    padding-right: 40px;
    &:after {
      content: '\f105'; } } }


/* long form video buttons */
.video-control-button {
  @include transition(all 0.2s ease);
  height: 80px;
  width: 80px;
  border-radius: $global-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 30px;
  &:hover {
    color: $white;
    background-color: rgba(255,255,255,0.2); } }

.video-setting-button-group {
  display: flex;
  align-items: center;
  > .video-setting-button:last-child {
    margin-right: 0; }
  .video-setting-button {
    @include transition(all 0.2s ease);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $global-radius;
    margin-right: 24px;
    color: $white;
    font-size: 22px;
    &.close-icon {
      font-size: 26px; }
    &:hover {
      color: $white;
      @include transform(translate(0, -2px)); } } }

.video-section-button-group {
  display: flex;
  align-items: center;
  > .video-section-button:last-child {
    margin-right: 0; }
  .video-section-button {
    @include transition(all 0.2s ease);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $global-radius;
    margin-right: 24px;
    color: $gray;
    font-size: 22px;
    &:hover {
      color: $gray;
      @include transform(translate(0, -2px)); } } }

.button.white {
  color: $text-gray;
  &.hollow, {
    &, &.disabled, &[disabled] {
      color:  $gray-light; }
    &:hover, &:focus {
      color: darken($text-gray, 5%); } } }

@each $name, $color in $color-map {
  .button {
    &.#{$name} {
      background-color: $color;
      border-color: $color;
      &:hover, &:focus {
        background-color: darken($color, 5%);
        border-color: darken($color, 5%); }
      &.hollow, {
        &, &.disabled, &[disabled] {
          background-color: transparent;
          border-color: $color;
          color: $color; }
        &:hover, &:focus {
          border-color: darken($color, 5%);
          color: darken($color, 5%); } } } } }
