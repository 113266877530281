.img-expand, .img-expand > img {
  width: 100%;
  height: auto; }
.img-circle, .img-circle > img {
  border-radius: 50%; }
.img-radius, .img-radius > img {
  border-radius: $global-radius; }

img {
  max-width: 100%;
  &[style*="float:left"] {
    margin: 0 $column-gutter $column-gutter / 2 0; }
  &[style*="float:right"] {
    margin: 0 0 $column-gutter / 2 $column-gutter; } }
