//manage program
.program-select section {
  padding: 2rem; }
//left content right img style
.divided-section {
  > .row {
    height: 100vh;
    margin-top: -72px;
    padding-top: 71px;
    .left-area, .right-area > .bg-contain {
      height: 100%; }
    .right-area {
      position: fixed;
      top: 52px;
      right: 0;
      bottom: 0;
      width: 100%;
      .pane-label-tag {
        top: 12px; } }
    .left-area {
      position: relative;
      .content-area {
        margin: 0 auto;
        .bottom-button {
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 99;
          background: white;
          border-top: 1px solid $border-color;
          box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.05);
          padding: 20px;
          text-align: center;
          width: 50%; }
        .equipment-tile {
          background-color: transparent;
          .card-section {
            background-color: $default-bg-gray; }
          .card-divider {
            margin-top: 5px;
            background-color: transparent; } }
        h1 {
          font-size: 34px; }
        h3 {
          font-size: 22px;
          font-weight: bold; } } } } }

.onboarding {
  min-height: 100vh;
  background-color: $default-bg-gray;
  overflow-y: scroll;
  > .row {
    min-height: 80vh; }
  section {
    padding: 2rem; }
  ul.survey-list {
    margin-bottom: 20px;
    li {
      list-style: none;
      .button {
        border: 1px solid $gray-light;
        box-shadow: 0 2px 9px -5px rgba(0,0,0,0.2);
        padding: 22px 20px;
        text-align: left; } } }
  .workout-content-block a.workout-tile {
    position: relative;
    height: 70vh;
    background-color: $white; }
  .row.onboarding-workouts { //recommend workout & all workout in onboarding
    margin-left: -15px; }
  .program-card {
    @include transition(all 0.2s ease);
    .program-card-image {
      @include background;
      padding-bottom: 68%;
      border-radius: $rebrand-global-radius;
      margin-bottom: 8px;
      position: relative;
      .program-card-tag {
        position: absolute;
        top: 15px;
        left: 15px;
        padding: 6px 8px;
        border-radius: 3px;
        background-color: $pink;
        color: $white;
        font-size: 10px; } }
    .program-subtitle, .program-title {
      margin-bottom: 0;
      font-weight: 600; }
    .program-subtitle {
      color: $pink;
      text-transform: uppercase;
      font-size: 11px; }
    .program-title {
      font-size: 20px;
      color: $gray-60; }
    .program-trainer {
      color: $gray-40;
      font-size: 13px; }
    &:hover {
      @include transform(scale(1.01)); } } }

.terms-iframe {
  width: 100%;
  height: auto;
  border: none;
  padding-top: 130px;
  min-height: 100vh;
  margin-top: -140px;
  padding-bottom: 80px;
  + .bottom-button {
    position: fixed;
    background: $white;
    bottom: 0;
    padding-top: 1rem;
    border-top: 1px solid $border-color;
    width: 100%;
    box-shadow: 0 3px 12px rgba(0,0,0,0.25); } }

.callout.program-item {
  @include box-shadow(0 4px 20px -2px rgba(0, 0, 0, 0.14));
  span.label {
    background: $white;
    color: $gray !important;
    border: 1px solid lighten($border-color, 15%);
    font-weight: bold;
    padding: .5rem; }
  .progress {
    background-color: white;
    border: 1px solid lighten($border-color, 15%);
    border-radius: 8px;
    .progress-meter {
      border-radius: 8px; } }
  &.kayla {
    > h5 {
      color: $pink; }
    .progress-meter {
      background: $pink; } }
  &.sjana {
    > h5 {
      color: $blue; }
    .progress-meter {
      background: $blue; } }
  &.kelsey {
    > h5 {
      color: $gray; }
    .progress-meter {
      background: $gray; } } }


#onboarding-workout-info {
  .trainer-img {
    background-repeat: no-repeat;
    height: 350px;
    position: relative;
    background-position: 40% center;
    width: 100%;
    background-size: cover; } }


@media #{$medium-only} {
  .divided-section {
    > .row {
      .right-area {
        top: 42px; } } } }


@media #{$small-only} {
  .onboarding {
    section {
      padding: 20px; }
    .row.onboarding-workouts {
      margin-left: 0; }
    .workout-content-block a.workout-tile {
      position: relative;
      height: 65vh;
      width: 340px; }
    .page-title .side-button {
      margin: 10px 0 !important; }
    .callout.program-item {
      background-image: unset !important; } }

  .divided-section > .row {
    .left-area .content-area {
      margin: 10px auto 0 auto;
      .bottom-button {
        width: 100%; } } } }



