.workout-content-block {
  .row-tiles {
    .owl-carousel {
      margin: 0 -2rem 25px -2rem;
      width: auto;
      .owl-item .item {
        padding-left: 2rem;
        margin-right: -0.5rem; } }
    .title-row {
      @include flex(center, flex-start);
      margin-bottom: 10px;
      > h2 {
        flex: 1;
        margin: 0; }
      > a {
        @include flex;
        color: $gray;
        i {
          font-size: 24px;
          margin-left: 4px; }
        &:hover {
          @include hover_action; } } }
    &:last-child .owl-carousel {
      margin-bottom: 0 !important; } }
  &.carousel-in-overlay {
    .owl-carousel {
      margin-left: -20px;
      margin-right: -20px;
      .owl-item .item {
        // padding-left: 20px
        padding: 10px;
        margin-right: 0; } } }
  // displayed in horizontal (challenge)
  .workout-tile {
    &.tall-tile {
      height: 50vh; }
    &.no-hover {
      &:hover {
        transform: none;
        @include box-shadow(0 3px 7px -2px rgba(0,0,0,.1));
        cursor: default; } }
    &.horizontal-tile {
      @extend .transit;
      width: 535px;
      border: 1px solid lighten($border-color, 15%);
      border-radius: $global-radius;
      @include box-shadow(0 3px 7px -2px rgba(0,0,0,.1));
      background-color: $white;
      overflow: hidden;
      margin-bottom: 1rem;
      .bg-area {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        // background-image: url("https://cdn.shopify.com/s/files/1/1872/0733/t/4/assets/se-about-bg.jpg?10130944130337548536")
        background-color: $card-img-default-bg;
        .category-img {
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top; } }
      .description-area {
        border-left: 1px solid lighten($border-color, 15%);
        .table-cell {
          height: 230px;
          > .tile-top {
            position: relative;
            padding-right: 40px; }
          .completed-status {
            top: 0 !important;
            right: 0 !important; } }
        .tile-detail {
          color: $gray-light;
          font-size: 14px;
          line-height: 1.3;
          font-weight: 600;
          p {
            margin-bottom: 0; } } } }
    &.card {
      @extend .transit;
      border: 1px solid lighten($border-color, 15%);
      position: relative;
      @include box-shadow(0 3px 7px -2px rgba(0,0,0,.1));
      width: 350px;
      cursor: pointer;
      &.overview-exercise {
        .card-divider .tile-detail {
          color: $black !important;
          font-size: 18px;
          font-weight: bold; } }
      &.timer-card {
        .card-section {
          background-image: none;
          background: white;
          position: relative;
          .timer-circle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            height: 80%; }
          .timer-number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 3rem; } } }
      &.equipment-tile {
        overflow: hidden;
        width: 160px;
        @include box-shadow(none);
        border: none;
        border-radius: 0;
        .card-section {
          background-image: none;
          border-bottom: none;
          height: 160px;
          padding: 10px 5px;
          border-radius: $rebrand-global-radius;
          .category-img {
            background-size: contain; }
          .card-color-layer {
            background-color: $gray-60;
            opacity: 0.1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; } }
        &.small {
          width: 190px;
          .card-section {
            height: 120px; }
          .table-block {
            height: 65px; } } }
      h2, h3, h4, p {
        margin-bottom: 0; }
      .card-section {
        padding: 0;
        height: 250px;
        border-bottom: 1px solid lighten($border-color, 15%);
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        // background-image: url("https://cdn.shopify.com/s/files/1/1872/0733/t/4/assets/se-about-bg.jpg?10130944130337548536")
        background-color: $card-img-default-bg;
        &.no-default-bg {
          background-image: none;
          .category-img {
            background-size: contain; } }
        .category-img {
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top;
          .play-icon {
            width: 40px;
            height: 36px;
            border-radius: $global-radius;
            background: $white;
            @include box-shadow(0 5px 8px rgba(0,0,0,.15));
            font-size: 30px;
            line-height: 1;
            padding-top: 2px;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); } } }
      .card-divider {
        padding: 10px 20px;
        > .table-block { //using table-block for 2 lines workout title
          height: 80px;
          .table-cell {
            padding: 0; } }
        .tile-detail {
          font-weight: 600;
          margin-top: 5px;
          line-height: 1;
          letter-spacing: -0.3px;
          font-size: 1rem;
          @extend .text-gray; } }
      &.simple-card {
        width: 535px;
        .card-section {
          height: 64px; }
        .card-divider {
          padding: 15px 20px;
          .tile-top {
            position: relative;
            padding-right: 60px; } }
        .completed-status {
          top: 0 !important;
          right: 0 !important; } } }
    &:hover {
      @include transform(scale(1.01));
      @include box-shadow(0 4px 15px -2px rgba(0, 0, 0, 0.12)); } }
  .row-tiles {
    position: relative;
    .cardio-completed {
      margin: -15px 0 40px 0;
      ul.completed-list {
        > li {
          padding: 0 20px;
          margin: 0;
          &:not(:last-child) {
            border-right: 1px solid $border-color; }
          &:first-child {
            padding-left: 0; }
          > ul.list-inline > li p {
            margin-bottom: 0;
            color: $gray;
            font-weight: 600;
            line-height: 1.3;
            font-size: 14px; } } } }
    .owl-carousel.workout-carousel {
      .owl-stage-outer {
        padding-top: 10px; }
      a.workout-tile {
        .completed-status {
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 1;
          svg * {
            stroke: transparent !important; }
          span.label {
            padding: 6px 10px;
            border-radius: 1000px; } } } } } }

@media #{$small-only} {
  .workout-content-block {
    .workout-tile {
      &.horizontal-tile {
        margin-bottom: 10px;
        width: 340px; }
      &.card {
        width: 340px;
        margin-bottom: 10px;
        .card-section {
          height: 240px; } } }
    .more-challenges {
      margin-top: 10px;
      .workout-tile {
        width: 100%; } }
    .row-tiles {
      .owl-carousel.workout-carousel.collapse-carousel {
        margin: 15px 0 40px 0;
        .item {
          a.workout-tile {
            width: 100%; } } }
      .cardio-completed ul.completed-list {
        > li {
          padding: 0;
          border-right: none !important;
          margin-bottom: 10px;
          display: block; } } } } }

