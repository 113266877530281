form, .form {
  .label-block {
    display: block;
    margin: rem-calc(0 0 3 5);
    font-weight: bold;
    font-size: rem-calc(14);
    color: $gray-light; }
  input, select, textarea {
    padding: 0;
    border-radius: 0;
    color: $black;
    height: rem-calc(35);
    border-bottom: 1px solid $border-color;
    &:focus, &.focused {
      border-bottom: 1px solid $pink; } }
  select {
    background: none;
    -webkit-appearance: none; }
  input[type='radio'], input[type='checkbox'] {
    height: auto; } }


.field.float-label-field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 10px;
  position: relative;
  > small {
    order: 1;
    margin-top: -14px;
    margin-bottom: 1rem;
    font-size: 12px;
    color: $gray;
    &.error {
      color: $alert; } }
  > input, textarea {
    order: 2; }
  > label {
    order: 3; }
  label, input {
    transition: all 0.2s;
    touch-action: manipulation; }
  &.field-error {
    > label {
      color: $alert; }
    > input, textarea {
      border-bottom-color: $alert; } }
  input {
    font-family: inherit;
    -webkit-appearance: none;
    padding: 0;
    cursor: text;
    &:focus, &.focused {
      outline: 0; }
    // &:placeholder-shown + label
    //   cursor: text
    //   max-width: 66.66%
    //   white-space: nowrap
    //   overflow: hidden
    //   text-overflow: ellipsis
    //   transform-origin: left bottom
    //   transform: translate(0, 1.5rem) scale(1.15)
    &:focus::-webkit-input-placeholder, &.focused::-webkit-input-placeholder {
      opacity: 1; }
    // &::-webkit-input-placeholder
    //   opacity: 0
    //   transition: inherit
    &:not(:placeholder-shown) + label,
    &:focus + label {
      transform: translate(0, 0) scale(1);
      cursor: pointer; } }
  .input-unit {
    position: absolute;
    bottom: 22px;
    right: 15px; }
  i.select-icon {
    position: absolute;
    right: 15px;
    bottom: 30px; }
  i.fb-icon {
    position: absolute;
    font-size: 17px; }
  textarea {
    margin-top: 0.5rem;
    height: 2.8rem;
    min-height: 2.8rem;
    line-height: 1.4; } }

.radio-container, .checkbox-container {
  display: flex;
  align-items: center;
  min-height: 35px;
  > input {
    position: static;
    min-height: 1rem;
    min-width: 1rem;
    margin: 0 0.5rem 0 0; }
  > .radiomark, .checkmark {
    top: 50%;
    @include transform(translateY(-50%)); }
  > .label-text {
    line-height: 1; } }

.radio-checkbox-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > label.radio-container, >label.checkbox-container {
    margin-right: 2rem; } }
