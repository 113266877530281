$column-gutter: 20px;

$size-list: 0 2 3 4 5 6 9 10 13 15 20 25 30 33 40 50 60 70 75 80 90 100 120 130 150 200 220 250 280 300 335 350 400 500 540;
$font-list: 4 6 8 10 12 14 16 18 20 22 24 26 28 30 40 45 50 60 70 80 90 100 120 150;
$align-list: left center right;
$sides-list: top bottom left right;
$screen-list: small medium large;
// $breakpoint-list: "#{$small-up}" "#{$medium-up}" "#{$large-up}" "#{$xlarge-up}"

@mixin items {
  .#{$screen}-table-block {
    display: table;
    position: static;
    padding: 0;
    margin: 0;
    width: 100%;
    table-layout: fixed;
    & > .table-cell {
      display: table-cell;
      padding: $column-gutter/2;
      height: 100%;
      vertical-align: middle; } }

  // Auto width
  .#{$screen}-width-auto {
    width: auto !important; }

  // Margin Auto
  .#{$screen}-margin-auto {
    margin-left: auto;
    margin-right: auto; }

  // Margin Reset
  .#{$screen}-margin-0 {
    margin: 0 !important; }

  @each $i in $size-list {
    // Width Percentage
    .#{$screen}-width-#{$i} {
      width: 1% * $i !important; }

    // Width Pixel
    .#{$screen}-width-#{$i}px {
      width: 1px * $i !important; }

    // margin
    .#{$screen}-margin-#{$i} {
      margin: 1px * $i !important; }

    // Padding
    .#{$screen}-padding-#{$i} {
      padding: 1px * $i !important; }

    // Opacity
    .#{$screen}-opacity-#{$i} {
      opacity: $i / 100; }

    .#{$screen}-height-#{$i} {
      min-height: $i * 1vh !important; }

    .#{$screen}-height-#{$i}px {
      height: $i * 1px !important; }
    // Build for sides
    @each $side in $sides-list {
      // Paddings
      .#{$screen}-padding-#{$side}-#{$i} {
        padding-#{$side}: 1px * $i !important; }

      // Margins
      .#{$screen}-margin-#{$side}-#{$i} {
        margin-#{$side}: 1px * $i !important; }

      .#{$screen}-margin-#{$side}-neg#{$i} {
        margin-#{$side}: -1px * $i !important; } } }

  @each $side in $sides-list {
    // Side Borders
    .#{$screen}-border-#{$side} {
      border-#{$side}-width: 1px;
      border-#{$side}-style: solid;
      border-color: transparent; } }


  @each $fs in $font-list {
    // Font Sizes
    .#{$screen}-fontsize-#{$fs} {
      font-size: rem-calc(1 * $fs) !important; } }

  @each $ta in $align-list {
    // Text Align
    .#{$screen}-text-#{$ta} {
      text-align: $ta !important; }

    // Float
    .#{$screen}-float-#{$ta} {
      float: $ta !important; } } }


// nc-icon
@each $i in $size-list {
  svg.nc-icon.size-#{$i} {
    width: 1px * $i !important;
    height: 1px * $i !important; } }


// ----------------------
// Ok let's build these items in each media CLASS
// TODO: Turn into mixin
// ----------------------

@each $i in $screen-list {
  @if $i == 'small' {
    @media #{$small-up} {
      $screen: $i;
      @include items; } }

  @if $i == 'medium' {
    @media #{$medium-up} {
      $screen: $i;
      @include items; } }

  @if $i == 'large' {
    @media #{$large-up} {
      $screen: $i;
      @include items; } } }

// Vertically middles and element
@mixin middle {
  @include transform(translate(-50%, -50%));
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%; }
.middle {
  @include middle; }

@mixin bottom {
  @include transform(translate(-50%, 0));
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%; }

