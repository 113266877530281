@each $name, $color in $color-map {
  .border-#{$name} {
    border: 1px solid $color !important; }

  .border-color-#{$name} {
    border-color: $color !important; }

  svg.nc-icon {
    &.#{$name} * {
      stroke: $color !important; } }

  svg {
    &.fill-#{$name} * {
      fill: $color !important; } }

  .bg-#{$name} {
    background: $color !important; }

  .alert-box.#{$name} {
    background: $color;
    border-color: darken($color, 10%); }

  .label.#{$name} {
    background: $color; }

  .badge-#{$name}, .status-#{$name} {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin: rem-calc(1 0);
    padding: rem-calc(1 5);
    min-width: rem-calc(25);
    background: $color;
    color: white;
    border-radius: 1000px;
    font-size: rem-calc(12);
    font-family: $body-font-family; }

  .status-#{$name} {
    margin: -2px 0 0;
    padding: 0;
    min-width: 0;
    width: rem-calc(10);
    height: rem-calc(10);
    border: 1px solid darken($color, 10%); }

  .panel.panel-#{$name} {
    border-color: $color;
    .panel-heading {
      background: $color;
      color: white;
      * {
        color: white; } } } }

.border-none {
  border: none !important; }

svg.nc-icon {
  &.nc-icon-14 {
    width: rem-calc(14);
    height: rem-calc(14); }
  &.nc-icon-16 {
    width: rem-calc(16);
    height: rem-calc(16); }
  &.nc-icon-24 {
    width: rem-calc(24);
    height: rem-calc(24); }
  &.nc-icon-32 {
    width: rem-calc(32);
    height: rem-calc(32); }
  &.nc-icon-96 {
    width: rem-calc(96);
    height: rem-calc(96); } }
